import React, {useRef} from 'react';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import OceanTheme from "fusioncharts/themes/fusioncharts.theme.ocean";
import CarbonTheme from "fusioncharts/themes/fusioncharts.theme.carbon";
import GammeTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import CandyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import ZuneTheme from "fusioncharts/themes/fusioncharts.theme.zune";
import UmberTheme from "fusioncharts/themes/fusioncharts.theme.umber";
import './modal.css';

const Adminsondageviews = ({show, onClose, data, period}) => {
    const printRef = useRef();
    ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, OceanTheme, CarbonTheme, GammeTheme, CandyTheme, ZuneTheme, UmberTheme);    
    const formatDeDate = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'long'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = new Intl.DateTimeFormat('fr-FR', {month:'short'}).format(date);
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${jr} ${jour} ${mois} ${annee} - ${heure}:${min}:${sec}`; 
	};
    const dataSourceQ11 = {
        chart: {
            caption: "Q1.1. Domaine d'activité",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Agriculture d'exportation", value: data.filter(item => item.q11value === "agriculturedexportation").length},
            {label: "Agriculture vivrière, élevage", value: data.filter(item => item.q11value === "agriculturevivriereelevage").length},
            {label: "Pêche, sylviculture", value: data.filter(item => item.q11value === "pechesylviculture").length},
            {label: "Autres industries", value: data.filter(item => item.q11value === "autresindustries").length},
            {label: "BTP", value: data.filter(item => item.q11value === "btp").length},
            {label: "Energie (eau, gaz, électricité)", value: data.filter(item => item.q11value === "energieeaugazelectricite").length},
            {label: "Extraction minière", value: data.filter(item => item.q11value === "extractionminiere").length},
            {label: "ndustries agro-alimentaires", value: data.filter(item => item.q11value === "industriesagroalimentaires").length},
            {label: "Industries pétrolières", value: data.filter(item => item.q11value === "industriespetroliere").length},
            {label: "Banques et Ets financiers", value: data.filter(item => item.q11value === "banquesetetsfinanciers").length},
            {label: "Commerce", value: data.filter(item => item.q11value === "commerce").length},
            {label: "Services", value: data.filter(item => item.q11value === "services").length},
            {label: "Administration publique", value: data.filter(item => item.q11value === "administrationpublique").length},
            {label: "Télécommunications", value: data.filter(item => item.q11value === "telecommunications").length},
            {label: "Transports, communications", value: data.filter(item => item.q11value === "transportscommunications").length},
        ]
    };
    const dataSourceQ12 = {
        chart: {
            caption: "Q1.2. Régime d'imposition :",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Régime de l'Entreprenant", value: data.filter(item => item.q12value === "regimedelentreprenant").length},
            {label: "Régime des Microentreprises", value: data.filter(item => item.q12value === "regimedesmicroentreprises").length},
            {label: "Régime du Réel Normal", value: data.filter(item => item.q12value === "regimedureelnormal").length},
            {label: "Régime du Réel Simplifié", value: data.filter(item => item.q12value === "regimedureelsimplifie").length},
            {label: "Régime spécifique", value: data.filter(item => item.q12value === "regimespecifique").length},
        ]
    }; 
    const dataSourceQ14 = {
        chart: {
            caption: "Q1.4. Êtes-vous un Centre de Gestion Agréé (CGA) :",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "OUI", value: data.filter(item => item.q14value === "OUI").length},
            {label: "NON", value: data.filter(item => item.q14value === "NON").length},
        ]
    };  
    const dataSourceQ15 = {
        chart: {
            caption: "Q1.5. Indiquez l'année à laquelle vous avez utilisé la plateforme e-impots pour la première fois :",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "2015", value: data.filter(item => item.q15value === "2015").length},
            {label: "2016", value: data.filter(item => item.q15value === "2016").length},
            {label: "2017", value: data.filter(item => item.q15value === "2017").length},
            {label: "2018", value: data.filter(item => item.q15value === "2018").length},
            {label: "2019", value: data.filter(item => item.q15value === "2019").length},
            {label: "2020", value: data.filter(item => item.q15value === "2020").length},
            {label: "2021", value: data.filter(item => item.q15value === "2021").length},
            {label: "2022", value: data.filter(item => item.q15value === "2022").length},
            {label: "2023", value: data.filter(item => item.q15value === "2023").length},
            {label: "2024", value: data.filter(item => item.q15value === "2024").length},
        ]
    }; 
    const dataSourceQ21 = {
        chart: {
            caption: "Q2.1. Êtes-vous satisfait des procédures de déclarations à travers e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q21value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q21value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q21value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q21value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q21value === "Neutre").length},
        ]
    }; 
    const dataSourceQ31 = {
        chart: {
            caption: "Q3.1. Indiquez les type des paiement que vous utilisez le plus souvent.",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Télépaiement", value: data.filter(item => item.q31value1 === "Télépaiement").length},
            {label: "Virement bancaire", value: data.filter(item => item.q31value2 === "Virement bancaire").length},
            {label: "Chèque", value: data.filter(item => item.q31value3 === "Chèque").length},
            {label: "Espèce", value: data.filter(item => item.q31value4 === "Espèce").length},
            {label: "DGI mobile", value: data.filter(item => item.q31value5 === "DGI mobile").length},            
        ]
    }; 
    const dataSourceQ32 = {
        chart: {
            caption: "Q3.2. Êtes-vous satisfait des procédures de paiement à travers e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q32value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q32value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q32value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q32value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q32value === "Neutre").length},
        ]
    };
    const dataSourceQ41 = {
        chart: {
            caption: "Q4.1. Indiquez les années pour lesquelles vous avez effectué des dépôts en ligne.",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "2018", value: data.filter(item => item.q41value1 === "2018").length},
            {label: "2019", value: data.filter(item => item.q41value2 === "2019").length},
            {label: "2020", value: data.filter(item => item.q41value3 === "2020").length},
            {label: "2021", value: data.filter(item => item.q41value4 === "2021").length},
            {label: "2022", value: data.filter(item => item.q41value5 === "2022").length},            
            {label: "2023", value: data.filter(item => item.q41value6 === "2023").length},            
        ]
    };
    const dataSourceQ42 = {
        chart: {
            caption: "Q4.2. Êtes-vous satisfait des procédures de dépôt des états financiers à travers e-impots ?",
            yAxisName: "Participant(s)",
            theme: 'candy',
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q42value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q42value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q42value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q42value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q42value === "Neutre").length},
        ]
    };
    const dataSourceQ51 = {
        chart: {
            caption: "Q5.1. Avez-vous déjà effectué une demande de remboursement de crédits TVA via e-impots ?",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "OUI", value: data.filter(item => item.q51value === "OUI").length},
            {label: "NON", value: data.filter(item => item.q51value === "NON").length},
        ]
    }; 
    const dataSourceQ53 = {
        chart: {
            caption: "Q5.3. Êtes-vous satisfait des procédures de demande de remboursement à travers e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q53value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q53value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q53value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q53value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q53value === "Neutre").length},
        ]
    }; 
    const dataSourceQ61 = {
        chart: {
            caption: "Q6.1. Avez-vous déjà effectué une demande d'actes administratifs via e-impots ?",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "OUI", value: data.filter(item => item.q61value === "OUI").length},
            {label: "NON", value: data.filter(item => item.q61value === "NON").length},
        ]
    }; 
    const dataSourceQ62 = {
        chart: {
            caption: "Q6.2. Êtes-vous satisfait des procédures de demande d'actes à travers e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q62value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q62value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q62value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q62value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q62value === "Neutre").length},
        ]
    };
    const dataSourceQ63 = {
        chart: {
            caption: "Q6.3. Les actes sont-ils délivrés dans les délais indiqués ?",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "OUI", value: data.filter(item => item.q63value === "OUI").length},
            {label: "NON", value: data.filter(item => item.q63value === "NON").length},
        ]
    }; 
    const dataSourceQ71 = {
        chart: {
            caption: "Q7.1. Quelle est votre niveau de confiance en la sécurisation de vos données sur e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très confiant", value: data.filter(item => item.q71value === "Très confiant").length},
            {label: "Moyennement confiant", value: data.filter(item => item.q71value === "Moyennement confiant").length},
            {label: "Peu confiant", value: data.filter(item => item.q71value === "Peu confiant").length},
            {label: "Pas du tout confiant", value: data.filter(item => item.q71value === "Pas du tout confiant").length},
        ]
    };
    const dataSourceQ72 = {
        chart: {
            caption: "Q7.2. Avez-vous reçu une quelconque assistance relative aux difficultés rencontrées sur e-impots ?",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "OUI", value: data.filter(item => item.q72value === "OUI").length},
            {label: "NON", value: data.filter(item => item.q72value === "NON").length},
        ]
    }; 
    const dataSourceQ73 = {
        chart: {
            caption: "Q7.3. Si OUI, quels types d'assistance avez-vous reçu ?",
            theme: "gamme1",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Physique (formation)", value: data.filter(item => item.q73value1 === "Physique (formation)").length},
            {label: "Electronique", value: data.filter(item => item.q73value2 === "Electronique").length},
            {label: "Téléphonique", value: data.filter(item => item.q73value3 === "Téléphonique").length},
            {label: "Brochures explicatives", value: data.filter(item => item.q73value4 === "Brochures explicatives").length},  
            {label: "Autres", value: data.filter(item => item.q73value5 =! "").length},     
        ]
    }; 
    const dataSourceQ74 = {
        chart: {
            caption: "Q7.4. Êtes-vous satisfait du programme d'assistance à l'utilisation de e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q74value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q74value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q74value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q74value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q74value === "Neutre").length},
        ]
    };
    const dataSourceQ76 = {
        chart: {
            caption: "Q7.6. Dans quelles mesures e-impots a-t-elle impacté le temps mis pour faire vos démarches ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Forte réduction", value: data.filter(item => item.q76value === "Forte réduction").length},
            {label: "Faible réduction", value: data.filter(item => item.q76value === "Faible réduction").length},
            {label: "Aucun Changement", value: data.filter(item => item.q76value === "Aucun changement").length},
            {label: "Augmentation", value: data.filter(item => item.q76value === "Augmentation").length},
        ]
    };
    const dataSourceQ77 = {
        chart: {
            caption: "Q7.7. Quelles est votre appréciation générale de e-impots ?",
            theme: "candy",
            yAxisName: "Participant(s)",
            labelRotation: "90",
        },
        data: [
            {label: "Très satisfait", value: data.filter(item => item.q77value === "Très satisfait").length},
            {label: "Satisfait", value: data.filter(item => item.q77value === "Satisfait").length},
            {label: "Peu satisfait", value: data.filter(item => item.q77value === "Peu satisfait").length},
            {label: "Pas du tout satisfait", value: data.filter(item => item.q77value === "Pas du tout satisfait").length},
            {label: "Neutre", value: data.filter(item => item.q77value === "Neutre").length},
        ]
    }; 
    const handlePrint = () => {
        const printDoc = printRef.current;
        const win = window.open('', '', 'width=1368, height=720');
        if (win) {
            win.document.write(`
                <html>
                    <head>
                        <title>Impression de questionnaire</title>
                        <style>
                            body {
                                margin: 2em;
                                display: flex;
                                flex-direction: column;
                                height: 100vh;
                                justify-content: space-between;
                            }
                            header {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin: 0 1em;
                            }
                            .head-top {
                                justify-content: space-between;
                                display: flex;
                                flex-direction: row;
                            }
                            .head-title, .head-title2 {
                                text-transform: uppercase;
                                font-size: 12px;
                                letter-spacing: 1px;
                            }
                            .head-title > p {
                                margin: 0;
                            }
                            .head-img, .head-title2, .head-title {
                                width: 30%;
                                text-align: center;
                            }
                            .head-title2 > p {
                                margin: 0;
                            }
                            .title2-min {
                                font-size: 11px;
                                text-transform: capitalize !important;
                            }
                            .page-title {
                                background: #797979;
                                font-size: 2em;
                                text-transform: uppercase;
                                font-family: serif;
                                font-weight: 400;
                                text-align: center;
                                padding: 10px 20px;
                                margin: 2em 0 1em 0;
                            }
                            .page-title > p {
                                margin: 0;
                            }
                            footer {
                                border-top: 1px solid #797979;
                                margin: 2em 4em;
                                color: #797979;
                                font-size: 11px;
                                text-align: center;
                                font-style: italic;
                                letter-spacing: 0.4px;
                                padding-bottom: 2em;
                            }
                            footer > p {
                                margin: 0;
                            }
                        </style>
                    </head>
                    <body>
                        <header>
                            <div class="head-top">
                                <div class="head-title">
                                    <p>ministere des finances et du budget</p>
                                    <p>--------</p>
                                    <p>direction generale des impots</p>
                                    <p>--------</p>
                                    <p>direction de la strategie des etudes et des statistiques fiscales</p>
                                </div>
                                <div class="head-img">
                                    <img src="https://sondages.hermannlepatriote.com/public/logo.png" height="100" width="150" alt="logoDGI" />
                                </div>
                                <div class="head-title2">
                                    <p>republique de cote d'ivoire</p>
                                    <p class="title2-min">union - discipline - travail</p>
                                    <p>--------</p>
                                </div>
                            </div>
                            <div class="page-title">
                                <p>questionnaire<br/>impact de la digitalisation sur la satisfaction des usagers-clients de la dgi</p>		
                            </div>
                        </header>		
                        <div>
                            ${printDoc.innerHTML}
                        </div>
                        <footer>
                            <p>DSESF - Abidjan - Plateau - Cité Administrative - Tour E - 12<sup>ème</sup> étage - BP V 103 Abidjan - Tél. 27 20 22 73 31 - Fax 27 20 22 73 31</p>
                            <p>Site web : <u>www.dgi.gouv.ci</u> - Email : <u>infodgi@dgi.gouv.ci</u> - Ligne verte : 800 88 888</p>
                        </footer>
                    </body>
                </html>      
            `);
            win.document.close();
            win.focus();
            setTimeout(() => {
                win.print();
                win.close();
            }, 500);
        }
    };    
    if (!show) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <p className="mb-0">
                        Recherche par période
                    </p>
                    <div className="flex items-center">
                        <Button variant="dark" className="stats-print-btn mr-2" onClick={handlePrint} title="Impression des données">                            
                            <svg  className="w-[24px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z" clip-rule="evenodd"/>
                            </svg>                       
                        </Button>                        
                        <svg id="pointer-mouse" onClick={onClose} className="mr-4 w-[30px] h-[30px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.1" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                    </div>                    
                </div>
                <hr/>
                <div className="modal-body" ref={printRef}>
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between">
                            <p className="px-2 pt-3 mb-0">{period}</p>
                            <p className="px-2 pt-3 mb-0 text-xs">{'Imprimé le '+formatDeDate(new Date())}</p>
                        </div>                        
                        <hr/>
                        <div className='rounded p-4 mb-5'>
                            <h3>I. IDENTIFICATION</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ11} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" height="195" dataSource={dataSourceQ12} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ14} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ15} />
                                <br/>
                                <h5 className="mb-0">Q1.6. Fonction du répondant :</h5>
                                <table>
                                    {
                                        data.map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q16value}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                            <br/>
                            <h3>II. DECLARATIONS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ21} />
                                <br/>
                                <h5 className="mb-0">Q2.2. Relevez les difficultés rencontrées en faisant vos déclarations via e-impots.</h5>
                                <table>
                                    {
                                        data.filter(item => item.q22value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q22value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>
                                <br/>                                
                                <h5 className="mb-0">Q2.3. Quelles propositions faites-vous pour améliorer les procédures de déclarations ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q23value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q23value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>
                            </div>
                            <h3>III. PAIEMENTS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ31} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ32} />
                                <br/>
                                <h5 className="mb-0">Q3.3. Relevez les difficultés recontrées en faisant vos paiements via e-impots.</h5>
                                <table>
                                    {
                                        data.filter(item => item.q33value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q33value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>  
                                <br/>
                                <h5 className="mb-0">Q3.4. Quelles proposition faites-vous pour améliorer les procédures de paiement ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q34value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q34value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>                                
                            </div>
                            <br/>
                            <h3>IV. DEPOT DES ETATS FINANCIERS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ41} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ42} />
                                <br/>
                                <h5 className="mb-0">Q4.3. Relevez les difficultés rencontrées en faisant vos dépôts via e-impots.</h5>
                                <table>
                                    {
                                        data.filter(item => item.q43value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q43value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>  
                                <br/>
                                <h5 className="mb-0">Q4.4. Quelles propositions faites-vous pour améliorer les procédures de dépôt ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q43value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q43value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>  
                            </div>
                            <br/>
                            <h3>V. REMBOURSEMENT DE CREDITS TVA VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ51} />
                                <br/>
                                <h5 className="mb-0">Q5.2. Si OUI, indiquez la période de la première demande (mois/année) :</h5>
                                <table>
                                    {
                                        data.filter(item => item.q52value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q52value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table> 
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ53} />
                                <br/>
                                <h5 className="mb-0">Q5.4. Relevez les difficultés recontrées en faisant vos demandes via e-impots.</h5>
                                <table>
                                    {
                                        data.filter(item => item.q54value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q54value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table> 
                                <br/>
                                <h5 className="mb-0">Q5.5. Quelles proposition faites-vous pour améliorer les procédures de remboursement ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q55value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q55value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>                                 
                            </div>
                            <br/>
                            <h3>VI. ACTES ADMINISTRATIFS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ61} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ62} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ63} />
                                <br/>
                                <h5 className="mb-0">Q6.4. Relevez les difficultés recontrées en faisant vos demandes d'actes via e-impots.</h5>
                                <table>
                                    {
                                        data.filter(item => item.q64value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q64value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table> 
                                <br/>
                                <h5 className="mb-0">Q6.5. Quelles proposition faites-vous pour améliorer ces procédures ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q65value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q65value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>
                            </div>
                            <br/>
                            <h3>VII. APPRECIATION GENERALE</h3>
                            <div className="px-4 py-2 border mb-3">
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ71} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ72} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ73} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ74} />
                                <br/>
                                <h5 className="mb-0">Q7.5. Quelles propositions faites-vous pour améliorer l'assistance à l'utilisation de e-impots ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q75value != null)
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q75value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table> 
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ76} />
                                <br/>
                                <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={dataSourceQ77} />
                                <br/>
                                <h5 className="mb-0">Q7.8. Quelles propositions faites-vous pour améliorer la plateforme e-impots ?</h5>
                                <table>
                                    {
                                        data.filter(item => item.q78value != '')
                                        .map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>&nbsp;-&nbsp;</td>
                                                <td>{item.q78value}</td>
                                            </tr> 
                                        ))
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Adminsondageviews;

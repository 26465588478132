import React from 'react';
import {css} from '@emotion/react';
import {ClipLoader} from 'react-spinners';
import '../App.css';
import '../tailwind-file.css';
const styleSpiner = css`
	display: block;
	margin: 0 auto;
`;

function Esondageloading() {
	return (
		<div className="page-chargement flex justify-center w-screen h-screen">
			<ClipLoader color={'#f47a1d'} css={styleSpiner} size={55} />
		</div>
	);
}

export default Esondageloading;

import React from 'react';
import {Pie} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(PieController, ArcElement, Tooltip, Legend);

const Adminsondagechart = ({domaine1, domaine2, domaine3, domaine4, domaine5, domaine6, domaine7, domaine8, domaine9, domaine10, domaine11, domaine12, domaine13, domaine14}) => {
    const datachart = {
        labels: ['Agriculture dExportation', 'Agriculture Vivrière Elevage', 'Pêches sylviculture', 'Autres industries', 'BTP', 'Energie eau gaz électricité', 'Extraction minière', 'Industries agroalimentaires', 'Banques et Ets financiers', 'Commerce', 'Services', 'Administration publique', 'Télécommunications', 'Transports communications'],
        datasets: [
            {
                label: '',
                data: [domaine1, domaine2, domaine3, domaine4, domaine5, domaine6, domaine7, domaine8, domaine9, domaine10, domaine11, domaine12, domaine13, domaine14],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)', // Domaine 1
                    'rgba(54, 162, 235, 0.6)', // Domaine 2
                    'rgba(216, 255, 60, 0.6)',
                    'rgba(57, 227, 36, 0.6)',
                    'rgba(8, 195, 169, 0.6)',
                    'rgba(31, 180, 251, 0.6)',
                    'rgba(12, 46, 213, 0.6)',
                    'rgba(117, 18, 207, 0.6)',
                    'rgba(255, 38, 238, 0.6)',
                    'rgba(193, 44, 92, 0.6)',
                    'rgba(107, 58, 74, 0.6)',
                    'rgba(255, 109, 5, 0.6)',
                    'rgba(36, 109, 35, 0.6)',
                    'rgba(117, 117, 117, 0.6)',
                ],
            },
        ],
    };
    const opt = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Domaine dactivité',
            }
        },
    };
    return <Pie data={datachart} options={opt} />;
}

export default Adminsondagechart;

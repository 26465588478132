import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {ClipLoader, BarLoader} from 'react-spinners';
import {css} from '@emotion/react';
import {Link, useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Adminsondageview from './Adminsondageview';
import '../tailwind-file.css';

function Adminsondagevisiteurs() {
    const navigate = useNavigate(null);
    const [adminHorloge, setAdminhorloge] = useState('');
    const [dataVisites, setDatavisites] = useState([]);
    const [compteurVisites, setCompteur] = useState(0);
    const [adminLog, setAdminlog] = useState(localStorage.getItem('adminId'));
    const [internetAvailable, setInternetavailable] = useState(false);
    const [filterPeriodStart, setFilterperiodstart] = useState('');
    const [filterPeriodEnd, setFilterperiodend] = useState('');
    const [filterPeriodStarttmp, setFilterperiodstarttmp] = useState(null);
    const [filterPeriodEndtmp, setFilterperiodendtmp] = useState(null);
    const [compteurJanvier, setCompteurjanvier] = useState(0);
    const [compteurFevrier, setCompteurfevrier] = useState(0);
    const [compteurMars, setCompteurmars] = useState(0);
    const [compteurAvril, setCompteuravril] = useState(0);
    const [compteurMai, setCompteurmai] = useState(0);
    const [compteurJuin, setCompteurjuin] = useState(0);
    const [compteurJuillet, setCompteurjuillet] = useState(0);
    const [compteurAout, setCompteuraout] = useState(0);
    const [compteurSeptembre, setCompteurseptembre] = useState(0);
    const [compteurOctobre, setCompteuroctobre] = useState(0);
    const [compteurNovembre, setCompteurnovembre] = useState(0);
    const [compteurDecembre, setCompteurdecembre] = useState(0);
    const [compteurSondages, setCompteursondages] = useState(0);
    const [compteurVille, setCompteurville] = useState(0);
    const styleSpiner = css`
        display: block;
        margin: 0 auto;
    `;
    const checkAdminlog = () => {
        if (!adminLog) {
            navigate('/connection');
        }
    };
    const getData = async() => {
        try {
            const resp = await fetch('https://sondages.hermannlepatriote.com/compteursvisites');
            const compteur = await resp.json(); 
            setInternetavailable(true);
            setDatavisites(compteur.datavisites);
            setCompteur(compteur.compteurvisite);
            setCompteurjanvier(compteur.compteurjanvier);
            setCompteurfevrier(compteur.compteurfevrier);
            setCompteurmars(compteur.compteurmars);
            setCompteuravril(compteur.compteuravril);
            setCompteurmai(compteur.compteurmai);
            setCompteurjuin(compteur.compteurjuin);
            setCompteurjuillet(compteur.compteurjuillet);
            setCompteuraout(compteur.compteuraout);
            setCompteurseptembre(compteur.compteurseptembre);
            setCompteuroctobre(compteur.compteuroctobre);
            setCompteurnovembre(compteur.compteurnovembre);
            setCompteurdecembre(compteur.compteurdecembre);
            setCompteursondages(compteur.totalsondages);
            setCompteurville(compteur.compteurville);
        } catch(error) {
            setInternetavailable(false);
        }        
    };
    function logoutFunction() {
        localStorage.removeItem('adminId');
        setAdminlog(false);
        navigate('/connection');
    };
    const formatDeDate = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'long'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = new Intl.DateTimeFormat('fr-FR', {month:'short'}).format(date);
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${jr} ${jour} ${mois} ${annee} - ${heure}:${min}:${sec}`; 
	};
    const formatDeDateMoisAnnee = (date) => {
        const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		return `${mois}/${annee}`;
	};
    const colVisiteurs = [
        {
            name: "Date",
            selector: (row) => row.datevisite,
            sortable: true,
        },
        {
            name: "Ip",
            selector: (row) => row.ipaddr,
            sortable: false,
        },
        {
            name: "Adresse géographique",
            selector: (row) => <div style={{ whiteSpace: "pre-line" }}>{row.pays+'\n'+row.region+'\n'+row.ville}</div>,
            sortable: false,
        },
        {
            name: "Options",
            cell: (row) => (
                <div>
                    <Button>
                        Voir
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    useEffect(() => {
        const interval = setInterval(() => {
			var datecourrante = new Date();
			setAdminhorloge(formatDeDate(datecourrante));				
		}, 1000);
        checkAdminlog();
        getData();
    }, []);
    const handleFilterdateStart = (e) => {
        const selDateStart = e.target.value;
        const tmpValue = new Date(selDateStart).getTime();
        setFilterperiodstart(selDateStart);
        setFilterperiodstarttmp(Math.floor(tmpValue/1000));
    };
    const handleFilterdateEnd = (e) => {
        const selDateEnd = e.target.value;
        const tmpValue = new Date(selDateEnd).getTime();
        setFilterperiodend(selDateEnd);
        setFilterperiodendtmp(Math.floor(tmpValue/1000));
    };
    const getFilterdata = async() => {
        /*const filterSondageData = {
            datedebut: filterPeriodStarttmp,
            datefin: filterPeriodEndtmp,
        };
        try {
			const response = await axios.post('https://sondages.hermannlepatriote.com/filtersondages', filterSondageData);
			if (response.data.erreur == "ok") {
				alert('Une erreur est survenue !');
			} else {
                setDataquestionnaires(response.data.data);			
			} 
		} catch(error) {
			console.log('erreurfiltre',error);
		}*/
    };
    const validerFiltreperiodic = () => {
        getFilterdata();
    };
    const handleStatistiques = () => {
        navigate('/admin-statistiques');
    };
    const handleSondages = () => {
        navigate('/admin-sondage');
    };
    const handleQuestions = () => {
        navigate('/admin-questions');
    };
    const [currentMonth, setCurrentmonth] = useState(formatDeDateMoisAnnee(new Date()));

    return (
        <div className="statistiques-view">
            <div className="mb-0 admin-header" style={{ height: "3rem"}}>
				<div className="admin-header-title flex items-center"> 
                    <Link to="/admin-sondage" title="Accueil admin-sondage">
                        <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
                        </svg>
                    </Link>
                    <span>Espace Administration</span>
                </div>
                <div>
                    <svg onClick={logoutFunction} id="pointer-mouse" className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                    </svg> 
                </div> 
			</div>
            <hr/>
            <div className={(internetAvailable) ? 'admin-sondage-blocs' : 'hideitem'}>
                <Row className="flex px-2">
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleSondages}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Sondages</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21a9 9 0 1 1 0-18c1.052 0 2.062.18 3 .512M7 9.577l3.923 3.923 8.5-8.5M17 14v6m-3-3h6"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg1">
                            {compteurSondages}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleQuestions}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Questions</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-3 5h3m-6 0h.01M12 16h3m-6 0h.01M10 3v4h4V3h-4Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg2">
                            7
                        </span>
                    </Col>
                    <Col className="blocs-flex-col active-menu" id="pointer-mouse">
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Visiteurs</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg3">
                            {compteurVisites}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleStatistiques}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Statistiques</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 6.025A7.5 7.5 0 1 0 17.975 14H10V6.025Z"/>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.5 3c-.169 0-.334.014-.5.025V11h7.975c.011-.166.025-.331.025-.5A7.5 7.5 0 0 0 13.5 3Z"/>
                                </svg>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="flex p-2 mb-4">
                <span className="text-xs">Aujourd'hui le {adminHorloge}</span>
            </div>
            <hr/>
            <div className={(internetAvailable) ? 'p-2' : 'hideitem'}>
                <Row className="flex">
                    <Col className="m-2">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>- Nombres de visiteurs par mois</Accordion.Header>
                                <Accordion.Body>
                                    <div className="stats-div-container">
                                        <p className={(currentMonth == '1/2025') ? 'bg6 text-white' : ''}>Janvier <span>{compteurJanvier}</span></p>
                                        <p className={(currentMonth == '2/2025') ? 'bg6 text-white' : ''}>Février <span>{compteurFevrier}</span></p>
                                        <p className={(currentMonth == '3/2025') ? 'bg6 text-white' : ''}>Mars <span>{compteurMars}</span></p>
                                        <p className={(currentMonth == '4/2025') ? 'bg6 text-white' : ''}>Avril <span>{compteurAvril}</span></p>
                                        <p className={(currentMonth == '5/2025') ? 'bg6 text-white' : ''}>Mai <span>{compteurMai}</span></p>
                                        <p className={(currentMonth == '6/2025') ? 'bg6 text-white' : ''}>Juin <span>{compteurJuin}</span></p>
                                        <p className={(currentMonth == '7/2025') ? 'bg6 text-white' : ''}>Juillet <span>{compteurJuillet}</span></p>
                                        <p className={(currentMonth == '8/2025') ? 'bg6 text-white' : ''}>Août <span>{compteurAout}</span></p>
                                        <p className={(currentMonth == '9/2025') ? 'bg6 text-white' : ''}>Septembre <span>{compteurSeptembre}</span></p>
                                        <p className={(currentMonth == '10/2025') ? 'bg6 text-white' : ''}>Octobre <span>{compteurOctobre}</span></p>
                                        <p className={(currentMonth == '11/2025') ? 'bg6 text-white' : ''}>Novembre <span>{compteurNovembre}</span></p>
                                        <p className={(currentMonth == '12/2025') ? 'bg6 text-white' : ''}>Décembre <span>{compteurDecembre}</span></p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>- Classement des visiteurs par villes</Accordion.Header>
                                <Accordion.Body>
                                    {
                                        compteurVille && compteurVille.length > 0 ?
                                        (
                                            <div className="stats-div-container">
                                                {
                                                    compteurVille.map((item) => (
                                                        <p>{item.ville} <span>{item.total_ville}</span></p>
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            <span>aucune donnée diponible</span>
                                        )                                        
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col className="m-2">
                        <Row className="bg-gray1 p-2">
                            Adresse(s) de(s) visiteur(s)
                        </Row>                        
                        <DataTable 
                            className="shadow-md"
                            columns={colVisiteurs}
                            data={dataVisites}
                            pagination
                            paginationPerPage={7}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            highlightOnHover />
                    </Col>
                </Row>
            </div>
            <div className={(internetAvailable) ? 'hideitem' : 'page-erreur mt-4'}>
                <p className="flex flex-col align-items-center mb-0">
                    <BarLoader size={60} color={"green"} css={styleSpiner} />
                </p>
            </div>
        </div>
    );
}

export default Adminsondagevisiteurs;

import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import {Container, Stack, Navbar, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import PageFooter from './PageFooter';
import '../App.css';
import imgLogo from '../assets/logo.png';
import imgMfb from '../assets/logo-mfb.png';

const Esondageparticipation = () => {
	const [currentQuestion, setCurrentquestion] = useState(0);
	const [finQuestionnaire, setFinquestionnaire] = useState(false);
	const [clientIp, setClientip] = useState("");
	const [clientCity, setClientcity] = useState("");
	const [clientCountry, setClientcountry] = useState("");
	const [clientRegion, setClientregion] = useState("");
	const [selectedDate, setSelecteddate] = useState("");
	const [selectedDateMonth, setSelecteddatemonth] = useState("");
	const [selectedDateYear, setSelecteddateyear] = useState("");
	const navigate = useNavigate();
	const questionnaireContentRef = useRef(null);
	const questionItitle = useRef(null);
	const questionIItitle = useRef(null);
	const questionIIItitle = useRef(null);
	const questionIVtitle = useRef(null);
	const questionVtitle = useRef(null);
	const questionVItitle = useRef(null);
	const questionVIItitle = useRef(null);
	const [errorQ1, setErrorq1] = useState("");
	const [errorQ2, setErrorq2] = useState("");
	const [errorQ3, setErrorq3] = useState("");
	const [errorQ4, setErrorq4] = useState("");
	const [errorQ5, setErrorq5] = useState("");
	const [errorQ6, setErrorq6] = useState("");
	const [errorQ7, setErrorq7] = useState("");
	const [q52Enable, setQ52enable] = useState(false);
	const [q73Enable, setQ73enable] = useState(false);
	const [questionnaireOk, setQuestionnaireok] = useState(false);
	useEffect(() => {
		getUserIp();
	}, []);
	const scrollToQuestionTitle = (childRef) => {
		if (questionnaireContentRef.current && childRef.current) {
			questionnaireContentRef.current.scrollTop = childRef.current.offsetTop - questionnaireContentRef.current.offsetTop;
		}
	}
	const dateFormatted = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'short'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${mois}/${jour}/${annee} à ${heure}:${min}`;
	};
	const dateFormattedjma = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'short'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${jour}/${mois}/${annee}`;
	};
	const dateFormattedmois = (date) => {
		const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		return `${mois}/${annee}`;
	};
	/*const getUserLat = async() => {
		try {
			const resp = await fetch('https://sondages.hermannlepatriote.com/participations');
			const result = await resp.json();
			return result.compteurjanvier;
		} catch(error) {
			return "";
		}
	};
	const getUserLong = () => {
		const resp =  fetch('https://sondages.hermannlepatriote.com/participations');
		const result =  JSON.stringify(resp);
		return result.data.compteurjanvier;
	};*/
	const getUserVille = async() => {
		const response = await axios.get('https://sondages.hermannlepatriote.com/participations/');
		return response.data.compteurmars;
	};
	const initialStateQuestionnaire = {
		ipaddr: "",
		date: "",
		datesondage: dateFormattedjma(new Date()),
		moissondage: dateFormattedmois(new Date()),
		clientlat: "5.3536",	
		clientlong: "-4.0012",
		clientville: "Abidjan",
		q11label: "Q1.1. Domaine d'activité :",
		q11value: "",
		q12label: "Q1.2. Régime d'imposition :",
		q12value: "",
		q13label: "Q1.3. Centre des Impôts de Gestion :",
		q13value: "",
		q14label: "Q1.4. Êtes-vous un Centre de Gestion Agréé (CGA) :",
		q14value: "",
		q15label: "Q1.5. Indiquez l'année à laquelle vous avez utilisé la plateforme e-impots pour la première fois :",
		q15value: "",
		q16label: "Q1.6. Fonction du répondant :",
		q16value: "",
		q21label: "Q2.1. Êtes-vous satisfait des procédures de déclarations à travers e-impots ?",
		q21value: "",
		q22label: "Q2.2. Relevez les difficultés rencontrées en faisant vos déclarations via e-impots.",
		q22value: "",
		q23label: "Q2.3. Quelles propositions faites-vous pour améliorer les procédures de déclarations ?",
		q23value: "",
		q31label: "Q3.1. Indiquez les types de paiement que vous utilisez le plus souvent.",
		q31value1: "",
		q31value2: "",
		q31value3: "",
		q31value4: "",
		q31value5: "",
		q32label: "Q3.2. Êtes-vous satisfait des procédures de paiement à travers e-impots ?",
		q32value: "",
		q33label: "Q3.3. Relevez les difficultés recontrées en faisant vos paiements via e-impots.",
		q33value: "",
		q34label: "Q3.4. Quelles propositions faites-vous pour améliorer les procédures de paiement ?",
		q34value: "",
		q41label : "Q4.1. Indiquez les années pour lesquelles vous avez effectué des dépôts en ligne.",
		q41value1: "",
		q41value2: "",
		q41value3: "",
		q41value4: "",
		q41value5: "",
		q41value6: "",
		q42label: "Q4.2. Êtes-vous satisfait des procédures de dépôt des états financiers à travers e-impots ?",
		q42value: "",
		q43label: "Q4.3. Relevez les difficultés recontrées en faisant vos dépôt via e-impots.",
		q43value: "",
		q44label: "Q4.4. Quelles propositions faites-vous pour améliorer les procédures de dépôt ?",
		q44value: "",
		q51label: "Q5.1. Avez-vous déjà effectué une demande de remboursement de crédits TVA via e-impots ?",
		q51value: "",
		q52label: "Q5.2. Si OUI, indiquez la période de la première demande (mois/année) :",
		q52value: "",
		q53label: "Q5.3. Êtes-vous satisfait des procédures de demande de remboursement à travers e-impots ?",
		q53value: "",
		q54label: "Q5.4. Relevez les difficultés recontrées en faisant vos demandes via e-impots.",
		q54value: "",
		q55label: "Q5.5. Quelles proposition faites-vous pour améliorer les procédures de remboursement ?",
		q55value: "",
		q61label: "Q6.1. Avez-vous déjà effectué une demande d'actes administratifs via e-impots ?",
		q61value: "",
		q62label: "Q6.2. Êtes-vous satisfait des procédures de demande d'actes à travers e-impots ?",
		q62value: "",
		q63label: "Q6.3. Les actes sont-ils délivrés dans les délais indiqués ?",
		q63value: "",
		q64label: "Q6.4. Relevez les difficultés recontrées en faisant vos demandes d'actes via e-impots.",
		q64value: "",
		q65label: "Q6.5. Quelles proposition faites-vous pour améliorer ces procédures ?",
		q65value: "",
		q71label: "Q7.1. Quelle est votre niveau de confiance en la sécurisation de vos données sur e-impots ?",
		q71value: "",
		q72label: "Q7.2. Avez-vous reçu une quelconque assistance relative aux difficultés rencontrées sur e-impots ?",
		q72value: "",
		q73label: "Q7.3. Si OUI, quels types d'assistance avez-vous reçu ?",
		q73value1: "",
		q73value2: "",
		q73value3: "",
		q73value4: "",
		q73value5: "",
		q74label: "Q7.4. Êtes-vous satisfait du programme d'assistance à l'utilisation de e-impots ?",
		q74value: "",
		q75label: "Q7.5. Quelles propositions faites-vous pour améliorer l'assistance à l'utilisation de e-impots ?",
		q75value: "",
		q76label: "Q7.6. Dans quelles mesures e-impots a-t-elle impacté le temps mis pour faire vos démarches ?",
		q76value: "",
		q77label: "Q7.7. Quelles est votre appréciation générale de e-impots ?",
		q77value: "",
		q78label: "Q7.8. Quelles propositions faites-vous pour améliorer la plateforme e-impots ?",
		q78value: "",
		timestp: "",
	};
	const [questionnaireInfo, setQuestionnaireinfo] = useState(initialStateQuestionnaire); 
	const resetQuestionnaireInfo = () => {
		setQuestionnaireinfo(initialStateQuestionnaire);
		setErrorq1("");
		setErrorq2("");
		setErrorq3("");
		setErrorq4("");
		setErrorq5("");
		setErrorq6("");
		setErrorq7("");
	}
    const updateQuestionnaireInfo = useCallback((val) => {
        setQuestionnaireinfo(val);
    }, []);
	const handleQ31value1Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q31value1: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q31value1: ""});
		}	
	};
	const handleQ31value2Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q31value2: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q31value2: ""});
		}	
	};
	const handleQ31value3Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q31value3: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q31value3: ""});
		}	
	};
	const handleQ31value4Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q31value4: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q31value4: ""});
		}	
	};
	const handleQ31value5Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q31value5: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q31value5: ""});
		}	
	};
	const handleQ41value1Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q41value1: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q41value1: ""});
		}	
	};
	const handleQ41value2Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q41value2: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q41value2: ""});
		}	
	};
	const handleQ41value3Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q41value3: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q41value3: ""});
		}	
	};
	const handleQ41value4Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q41value4: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q41value4: ""});
		}	
	};
	const handleQ41value5Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q41value5: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q41value5: ""});
		}	
	};
	const handleQ41value6Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q41value6: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q41value6: ""});
		}	
	};
	const handleQ51valueChange = (eve) => {
		if (eve.target.value == "OUI") {
			setQ52enable(true);
		} 
		if (eve.target.value == "NON") {
			setQ52enable(false);
			setSelecteddate(null);
		} 
		updateQuestionnaireInfo({...questionnaireInfo, q51value: eve.target.value});		
	};
	const handleQ72valueChange = (e) => {
		updateQuestionnaireInfo({...questionnaireInfo, q72value: e.target.value});
		if (e.target.value == "OUI") {
			setQ73enable(true);
		} else {
			setQ73enable(false);
		}	
	};
	const handleQ73value1Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q73value1: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q73value1: ""});
		}	
	};
	const handleQ73value2Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q73value2: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q73value2: ""});
		}	
	};
	const handleQ73value3Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q73value3: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q73value3: ""});
		}	
	};
	const handleQ73value4Change = (event) => {
		if (event.target.checked) {
			updateQuestionnaireInfo({...questionnaireInfo, q73value4: event.target.value});
		} else {
			updateQuestionnaireInfo({...questionnaireInfo, q73value4: ""});
		}	
	};
	function gotoQuestionI() {
		setCurrentquestion(0);
	};
	function gotoQuestionIi() {
		setCurrentquestion(1);
	};
	function gotoQuestionIii() {
		setCurrentquestion(2);
	};
	function gotoQuestionIv() {
		setCurrentquestion(3);
	};
	function gotoQuestionV() {
		setCurrentquestion(4);
	};
	function gotoQuestionVi() {
		setCurrentquestion(5);
		setFinquestionnaire(false);
	};
	function gotoQuestionISuiv() {
		if (!questionnaireInfo.q11value) {
			setErrorq1("Erreur sur la question Q1.1.");
		} else {
			if (!questionnaireInfo.q12value) {
				setErrorq1("Erreur sur la question Q1.2.");
			} else {
				if (!questionnaireInfo.q13value) {
					setErrorq1("Erreur sur la question Q1.3.");
				} else {
					if (!questionnaireInfo.q14value) {
						setErrorq1("Erreur sur la question Q1.4.");
					} else {
						if (!questionnaireInfo.q15value) {
							setErrorq1("Erreur sur la question Q1.5.");
						} else {
							if (!questionnaireInfo.q16value) {
								setErrorq1("Erreur sur la question Q1.6.");
							} else {
								setErrorq1("");
								setErrorq2("");
								setErrorq3("");
								setErrorq4("");
								setErrorq5("");
								setErrorq6("");
								setErrorq7("");
								setCurrentquestion(1);
							}
						}
					}
				}
			}			
		}
	}
	function gotoQuestionIiSuiv() {
		if (!questionnaireInfo.q21value) {
			setErrorq2("Erreur sur la question Q2.1.");
		} else {
			setErrorq1("");
			setErrorq2("");
			setErrorq3("");
			setErrorq4("");
			setErrorq5("");
			setErrorq6("");
			setErrorq7("");
			setCurrentquestion(2);
		}
	};
	function gotoQuestionIiiSuiv() {
		if (!questionnaireInfo.q31value1 && !questionnaireInfo.q31value2 && !questionnaireInfo.q31value3 && !questionnaireInfo.q31value4 && !questionnaireInfo.q31value5) {
			setErrorq3("Erreur sur la question Q3.1.");
		} else {
			if (!questionnaireInfo.q32value) {
				setErrorq3("Erreur sur la question Q3.2.");
			} else {
				setErrorq1("");
				setErrorq2("");
				setErrorq3("");
				setErrorq4("");
				setErrorq5("");
				setErrorq6("");
				setErrorq7("");
				setCurrentquestion(3);
				updateQuestionnaireInfo({...questionnaireInfo, timestp: Math.floor(Date.now() / 1000)});
			}			
		}
	};
	function gotoQuestionIvSuiv() {
		if (!questionnaireInfo.q41value1 && !questionnaireInfo.q41value2 && !questionnaireInfo.q41value3 && !questionnaireInfo.q41value4 && !questionnaireInfo.q41value5 && !questionnaireInfo.q41value6) {
			setErrorq4("Erreur sur la question Q4.1.");
		} else {
			if (!questionnaireInfo.q42value) {
				setErrorq4("Erreur sur la question Q4.2.");
			} else {
				setErrorq1("");
				setErrorq2("");
				setErrorq3("");
				setErrorq4("");
				setErrorq5("");
				setErrorq6("");
				setErrorq7("");
				setCurrentquestion(4);
				updateQuestionnaireInfo({...questionnaireInfo, ipaddr: clientIp});
			}			
		}
	};
	function gotoQuestionVSuiv() {
		handleDateSetting();
		if (!questionnaireInfo.q51value) {
			setErrorq5("Erreur sur la question Q5.1.");
		} else {			
			if (!questionnaireInfo.q53value) {
				setErrorq5("Erreur sur la question Q5.3.");
			} else {
				setErrorq1("");
				setErrorq2("");
				setErrorq3("");
				setErrorq4("");
				setErrorq5("");
				setErrorq6("");
				setErrorq7("");					
				setCurrentquestion(5);
				updateQuestionnaireInfo({...questionnaireInfo, q52value: selectedDate});
			}			
		}
	};
	function gotoQuestionViSuiv() {
		if (!questionnaireInfo.q61value) {
			setErrorq6("Erreur sur la question Q6.1.");
		} else {			
			if (!questionnaireInfo.q62value) {
				setErrorq6("Erreur sur la question Q6.2.");
			} else {
				if (!questionnaireInfo.q63value) {
					setErrorq6("Erreur sur la question Q6.3.");
				} else {
					setErrorq1("");
					setErrorq2("");
					setErrorq3("");
					setErrorq4("");
					setErrorq5("");
					setErrorq6("");
					setErrorq7("");
					setCurrentquestion(6);
					updateQuestionnaireInfo({...questionnaireInfo, date: dateFormatted(new Date())});
				}				
			}			
		}
	};
	function gotoTerminer() {
		if (!questionnaireInfo.q71value) {
			setErrorq7("Erreur sur la question Q7.1.");
		} else {
			if (!questionnaireInfo.q72value) {
				setErrorq7("Erreur sur la question Q7.2.");
			} else {
				if (!questionnaireInfo.q74value) {
					setErrorq7("Erreur sur la question Q7.4.");
				} else {
					if (!questionnaireInfo.q76value) {
						setErrorq7("Erreur sur la question Q7.6.");
					} else {
						if (!questionnaireInfo.q77value) {
							setErrorq7("Erreur sur la question Q7.7.");
						} else {
							setErrorq1("");
							setErrorq2("");
							setErrorq3("");
							setErrorq4("");
							setErrorq5("");
							setErrorq6("");
							setErrorq7("");
							setFinquestionnaire(true);
							updateQuestionnaireInfo({...questionnaireInfo, q52value: selectedDate});							
						}
					}
				}
			}
		}
	};
	function recommencerQuestionnaire() {
		setFinquestionnaire(false);
		setCurrentquestion(0);
		resetQuestionnaireInfo();
	};
	const validerQuestionnaire = useCallback(async(e) => {
        e.preventDefault();		
		try {
			const response = await axios.post('https://sondages.hermannlepatriote.com/participation', questionnaireInfo);
			if (response.data.erreur == "identiqueip") {
				alert('Vous avez déjà répondu au questionnaire !');
				recommencerQuestionnaire();
			} else {
				if (response.data.erreur == "erreur") {
					return console.error(response.data.message);
				}
				if (response.data.message == "ok") {
					setQuestionnaireok(true);
				}			
			} 
		} catch(error) {
			console.log('erreursondage',error);
		}
    }, [questionnaireInfo, navigate]);
	const handleDateSelect = (e) => {
		let value = e.target.value;
		setSelecteddate(value);
		updateQuestionnaireInfo({...questionnaireInfo, q52value: e});
	};
	const handleDatemonthSelect = (e) => {
		let monthselect = e.target.value;
		if (monthselect != "") {
			setSelecteddatemonth(monthselect);
		} else {
			setSelecteddatemonth(monthselect);
		}
		handleDateSetting();
	};
	const handleDateyearSelect = (e) => {
		let yearselect = e.target.value;
		if (yearselect != "") {
			setSelecteddateyear(yearselect);
		} else {
			setSelecteddateyear(yearselect);
		}
		handleDateSetting();
	};
	const handleDateSetting = () => {
		if (selectedDateMonth) {
			if (selectedDateYear) {
				setSelecteddate(selectedDateMonth+'/'+selectedDateYear);
				updateQuestionnaireInfo({...questionnaireInfo, q52value: selectedDateMonth+'/'+selectedDateYear});
			} else {
				setSelecteddate("");
			}
		} else {
			setSelecteddate("");
		}
	};
	const getUserIp = async() => {
		try {
			const resp = await axios.get('https://ipapi.co/json/');
			setClientip(resp.data.ip);
			setClientcity(resp.data.city);
			setClientregion(resp.data.region);
			setClientcountry(resp.data.country_name);
		} catch(error) {
			console.error(error.message);
		}
	};
	
	return (
		<div>
			<div  className={questionnaireOk ? 'hideitem' : 'background-questionnaire'}>
				<Navbar className="questionnaire-navbar shadow-sm">
					<img className="logo-dgi" src={imgLogo} alt="logo dgi" />
					<p className="title-questionnaire">
						Impact de la digitalisation sur la satisfaction des usagers-clients de la DGI
					</p>
					<img className="logo-mfb" src={imgMfb} alt="logo mfb" />
				</Navbar>
				<br/><br/><br/>					
				<Container className="questionnaire-div">
					<div className={(finQuestionnaire == false) ? 'bg-question-item rounded' : 'hideitem'}>
						<Row>
							{/* question I */}
							<Col className={(currentQuestion == 0) ? '' : 'hideitem'}>
								<h2 className="text-question-title">I. IDENTIFICATION</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q1.1 Domaine d'activité :</p>
									<Form.Select value={questionnaireInfo.q11value} className="mb-3" aria-label="Q1.1. Domaine d'activité" onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q11value: e.target.value})}>
										<option value="">- - - - - - - - -</option>
										<option value="agriculturedexportation">Agriculture d'exportation</option>
										<option value="agriculturevivriereelevage">Agriculture vivrière, élevage</option>
										<option value="pechesylviculture">Pêche, sylviculture</option>
										<option value="autresindustries">Autres industries</option>
										<option value="btp">BTP</option>
										<option value="energieeaugazelectricite">Energie (eau, gaz, électricité)</option>
										<option value="extractionminiere">Extraction minière</option>
										<option value="industriesagroalimentaires">Industries agro-alimentaires</option>
										<option value="industriespetroliere">Industries pétrolières</option>
										<option value="banquesetetsfinanciers">Banques et Ets financiers</option>
										<option value="commerce">Commerce</option>
										<option value="services">Services</option>
										<option value="administrationpublique">Administration publique</option>
										<option value="telecommunications">Télécommunications</option>
										<option value="transportscommunications">Transports, communications</option>
									</Form.Select>
									<p className="mb-0">Q1.2. Régime d'imposition :</p>
									<Form.Select value={questionnaireInfo.q12value} className="mb-3" aria-label="Q1.2. Régime d'imposition" onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q12value: e.target.value})}>
										<option value="">- - - - - - - - -</option>
										<option value="regimedelentreprenant">Régime de l'Entreprenant</option>
										<option value="regimedesmicroentreprises">Régime des Microentreprises</option>
										<option value="regimedureelnormal">Régime du Réel Normal</option>
										<option value="regimedureelsimplifie">Régime du Réel Simplifié</option>
										<option value="regimespecifique">Régime spécifique</option>
									</Form.Select>
									<p className="mb-0">Q1.3. Centre des Impôts de Gestion :</p>
									<Form.Select value={questionnaireInfo.q13value} className="mb-3" aria-label="Q1.3. Centre des Impôts de Gestion" onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q13value: e.target.value})}>
										<option value="">- - - - - - - - -</option>
										<option value="dge">DGE</option>
										<option value="cmedjibi">CME Djibi</option>
										<option value="cmemarcory">CME Marcory</option>
										<option value="cmeplateau">CME Plateau</option>
										<option value="cmeportbouet">CME PORT-BOUET</option>
										<option value="said2pltxdjibi">SAID 2 PLTX DJIBI</option>
										<option value="said2pltxi">SAID 2 PLTX I</option>
										<option value="said2pltxii">SAID 2 PLTX II</option>
										<option value="said2pltxiii">SAID 2 PLTX III</option>
										<option value="saidabengourou">SAID ABENGOUROU</option>
										<option value="saidaboboi">SAID ABOBO I</option>
										<option value="saidaboboii">SAID ABOBO II</option>
										<option value="saidaboboiii">SAID ABOBO III</option>
										<option value="saidaboisso">SAID ABOISSO</option>
										<option value="saidadiake">SAID ADIAKE</option>
										<option value="saidadjamei">SAID ADJAME I</option>
										<option value="saidadjameii">SAID ADJAME II</option>
										<option value="saidadjameiii">SAID ADJAME III</option>
										<option value="saidadzope">SAID ADZOPE</option>
										<option value="saidagboville">SAID AGBOVILLE</option>
										<option value="saidagnibilekro">SAID AGNIBILEKROU</option>
										<option value="saidakoupe">SAID AKOUPE</option>
										<option value="saidalepe">SAID ALEPE</option>
										<option value="saidanyama">SAID ANYAMA</option>
										<option value="saidarrah">SAID ARRAH</option>
										<option value="saidattecoube">SAID ATTECOUBE</option>
										<option value="saidbangolo">SAID BANGOLO</option>
										<option value="saidbeoumi">SAID BEOUMI</option>
										<option value="saidbettie">SAID BETTIE</option>
										<option value="saidbiankouma">SAID BIANKOUMA</option>
										<option value="saidbietry">SAID BIETRY</option>
										<option value="saidbingerville">SAID BINGERVILLE</option>
										<option value="saidblolequin">SAID BLOLEQUIN</option>
										<option value="saidbocanda">SAID BOCANDA</option>
										<option value="saidbondoukou">SAID BONDOUKOU</option>
										<option value="saidbongouanou">SAID BONGOUANOU</option>
										<option value="saidbonon">SAID BONON</option>
										<option value="saidbonoua">SAID BONOUA</option>
										<option value="saidbouafle">SAID BOUAFLE</option>
										<option value="saidbouakei">SAID BOUAKE I</option>
										<option value="saidbouakeii">SAID BOUAKE II</option>
										<option value="saidbouna">SAID BOUNA</option>
										<option value="saidboundiali">SAID BOUNDIALI</option>
										<option value="saidcocody">SAID COCODY</option>
										<option value="saiddabakala">SAID DABAKALA</option>
										<option value="saiddabou">SAID DABOU</option>
										<option value="saiddaloai">SAID DALOA I</option>
										<option value="saiddaloaii">SAID DALOA II</option>
										<option value="saiddanane">SAID DANANE</option>
										<option value="saiddaoukro">SAID DAOUKRO</option>
										<option value="saiddikodougou">SAID DIKODOUGOU</option>
										<option value="saiddimbokro">SAID DIMBOKRO</option>
										<option value="saiddivo">SAID DIVO</option>
										<option value="saiddoropo">SAID DOROPO</option>
										<option value="saidduekoue">SAID DUEKOUE</option>
										<option value="saidferke">SAID FERKE</option>
										<option value="saidfresco">SAID FRESCO</option>
										<option value="saidgagnoa">SAID GAGNOA</option>
										<option value="saiddgbassam">SAID GD-BASSAM</option>
										<option value="saidgdlahou">SAID GD-LAHOU</option>
										<option value="saidguiglo">SAID GUIGLO</option>
										<option value="saidissia">SAID ISSIA</option>
										<option value="saidjacqueville">SAID JACQUEVILLE</option>
										<option value="saidkatiola">SAID KATIOLA</option>
										<option value="saidkong">SAID KONG</option>
										<option value="saidkorhogo">SAID KORHOGO</option>
										<option value="saidkouassidatekro">SAID KOUASSI-DATEKRO</option>
										<option value="saidkoumassii">SAID KOUMASSI I</option>
										<option value="saidkoumassiii">SAID KOUMASSI II</option>
										<option value="saidkounfao">SAID KOUN-FAO</option>
										<option value="saidlakota">SAID LAKOTA</option>
										<option value="saidman">SAID MAN</option>
										<option value="saidmankono">SAID MANKONO</option>
										<option value="saidmarcoryi">SAID MARCORY I</option>
										<option value="saidmarcoryii">SAID MARCORY II</option>
										<option value="saidmbahiakro">SAID M'BAHIAKRO</option>
										<option value="saidmbatto">SAID M'BATTO</option>
										<option value="saidmbengue">SAID M'BENGUE</option>
										<option value="saidmeagui">SAID MEAGUI</option>
										<option value="saidnassian">SAID NASSIAN</option>
										<option value="saidniable">SAID NIABLE</option>
										<option value="saidniakara">SAID NIAKARA</option>
										<option value="saidodienne">SAID ODIENNE</option>
										<option value="saidouangolo">SAID OUANGOLO</option>
										<option value="saidoume">SAID OUME</option>
										<option value="saidplateaui">SAID PLATEAU I</option>
										<option value="saidplateauii">SAID PLATEAU II</option>
										<option value="saidportbouet">SAID PORT-BOUET</option>
										<option value="saidrivierai">SAID RIVIERA I</option>
										<option value="saidrivieraii">SAID RIVIERA II</option>
										<option value="saidsakassou">SAID SAKASSOU</option>
										<option value="saidsanpedroi">SAID SAN-PEDRO I</option>
										<option value="saidsanpedroii">SAID SAN-PEDRO II</option>
										<option value="saidsassandra">SAID SASSANDRA</option>
										<option value="saidseguela">SAID SEGUELA</option>
										<option value="saidsikensi">SAID SIKENSI</option>
										<option value="saidsinfra">SAID SINFRA</option>
										<option value="saidsongon">SAID SONGON</option>
										<option value="saidsoubre">SAID SOUBRE</option>
										<option value="saidtaabo">SAID TAABO</option>
										<option value="saidtabou">SAID TABOU</option>
										<option value="saidtanda">SAID TANDA</option>
										<option value="saidtengrela">SAID TENGRELA</option>
										<option value="saidtiapoum">SAID TIAPOUM</option>
										<option value="saidtiassale">SAID TIASSALE</option>
										<option value="saidtiebissou">SAID TIEBISSOU</option>
										<option value="saidtouba">SAID TOUBA</option>
										<option value="saidtoulepleu">SAID TOULEPLEU</option>
										<option value="saidtoumodi">SAID TOUMODI</option>
										<option value="saidtreichvi">SAID TREICHV I</option>
										<option value="saidtreichvii">SAID TREICHV II</option>
										<option value="saidvavoua">SAID VAVOUA</option>
										<option value="saidwilliamsv">SAID WILLIAMSV</option>
										<option value="saidyakasseattobrou">SAID YAKASSE-ATTOBROU</option>
										<option value="saidyamoussoukro">SAID YAMOUSSOUKRO</option>
										<option value="saidyopougi">SAID YOPOUG I</option>
										<option value="saidyopougii">SAID YOPOUG II</option>
										<option value="saidyopougiii">SAID YOPOUG III</option>
										<option value="saidyopougiv">SAID YOPOUG IV</option>
										<option value="saidyopougv">SAID YOPOUG V</option>
										<option value="saidzonefranche">SAID ZONE FRANCHE</option>
										<option value="saidzoneiv">SAID ZONE IV</option>
										<option value="saidzuenoula">SAID ZUENOULA</option>
									</Form.Select>
									<p className="mb-0">Q1.4. Êtes-vous un Centre de Gestion Agréé (CGA) :</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="OUI"
													name="q14"
													id={`radio-${type}-1`}
													type={type}
													checked={questionnaireInfo.q14value == "OUI"}
													value="OUI"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q14value: e.target.value})}
												/>
												<Form.Check
													inline
													label="NON"
													name="q14"
													id={`radio-${type}-2`}
													type={type}
													checked={questionnaireInfo.q14value == "NON"}
													value="NON"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q14value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<p className="mb-0">Q1.5. Indiquez l'année à laquelle vous avez utilisé la plateforme e-impots pour la première fois :</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="2015"
													name="q15"
													id={`radio-${type}-11`}
													type={type}
													checked={questionnaireInfo.q15value == "2015"}
													value="2015"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2015"})}
												/>
												<Form.Check
													inline
													label="2016"
													name="q15"
													id={`radio-${type}-12`}
													type={type}
													checked={questionnaireInfo.q15value == "2016"}
													value="2016"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2016"})}
												/>
												<Form.Check
													inline
													label="2017"
													name="q15"
													id={`radio-${type}-3`}
													type={type}
													checked={questionnaireInfo.q15value == "2017"}
													value="2017"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2017"})}
												/>
												<Form.Check
													inline
													label="2018"
													name="q15"
													id={`radio-${type}-4`}
													type={type}
													checked={questionnaireInfo.q15value == "2018"}
													value="2018"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2018"})}
												/>
												<Form.Check
													inline
													label="2019"
													name="q15"
													id={`radio-${type}-5`}
													type={type}
													checked={questionnaireInfo.q15value == "2019"}
													value="2019"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2019"})}
												/>
												<Form.Check
													inline
													label="2020"
													name="q15"
													id={`radio-${type}-6`}
													type={type}
													checked={questionnaireInfo.q15value == "2020"}
													value="2020"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2020"})}
												/>
												<Form.Check
													inline
													label="2021"
													name="q15"
													id={`radio-${type}-7`}
													type={type}
													checked={questionnaireInfo.q15value == "2021"}
													value="2021"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2021"})}
												/>
												<Form.Check
													inline
													label="2022"
													name="q15"
													id={`radio-${type}-8`}
													type={type}
													checked={questionnaireInfo.q15value == "2022"}
													value="2022"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2022"})}
												/>
												<Form.Check
													inline
													label="2023"
													name="q15"
													id={`radio-${type}-9`}
													type={type}
													checked={questionnaireInfo.q15value == "2023"}
													value="2023"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2023"})}
												/>
												<Form.Check
													inline
													label="2024"
													name="q15"
													id={`radio-${type}-10`}
													type={type}
													checked={questionnaireInfo.q15value == "2024"}
													value="2024"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q15value: "2024"})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q1.6. Fonction du répondant :</Form.Label>
										<Form.Control value={questionnaireInfo.q16value} as="textarea" rows={1} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q16value: e.target.value})} />
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionISuiv} className="questionnaire-btn-suivant" variant="light">Suivant</Button>&nbsp;&nbsp;
									<p className={(errorQ1 == "") ? 'hideitem' : 'bg-error px-3 pb-1 m-0'}>{errorQ1}</p>								
								</div>
							</Col>
							{/* question II */}
							<Col className={(currentQuestion == 1) ? '' : 'hideitem'}>
								<h2 className="text-question-title">II. DECLARATION VIA E-IMPOTS</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q2.1. Êtes-vous satisfait des procédures de déclarations à travers e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q21"
													id={`radio-${type}-13`}
													type={type}
													checked={questionnaireInfo.q21value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q21value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q21"
													id={`radio-${type}-14`}
													type={type}
													checked={questionnaireInfo.q21value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q21value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q21"
													id={`radio-${type}-15`}
													type={type}
													checked={questionnaireInfo.q21value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q21value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q21"
													id={`radio-${type}-16`}
													type={type}
													checked={questionnaireInfo.q21value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q21value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q21"
													id={`radio-${type}-17`}
													type={type}
													checked={questionnaireInfo.q21value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q21value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q2.2. Relevez les difficultés rencontrées en faisant vos déclarations via e-impots.</Form.Label>
										<Form.Control value={questionnaireInfo.q22value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q22value: e.target.value})} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q2.3. Quelles propositions faites-vous pour améliorer les procédures de déclarations ?</Form.Label>
										<Form.Control value={questionnaireInfo.q23value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q23value: e.target.value})} />
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionI} className="questionnaire-btn-precedent" variant="light">Précédent</Button>&nbsp;
									<Button onClick={gotoQuestionIiSuiv} className="questionnaire-btn-suivant" variant="light">Suivant</Button>&nbsp;&nbsp;
									<span className={(errorQ2 == "") ? 'hideitem' : 'bg-error px-3 pb-1'}>{errorQ2} </span>
								</div>	
							</Col>
							{/* question III */}
							<Col className={(currentQuestion == 2) ? '' : 'hideitem'}>
								<h2 className="text-question-title">III. PAIEMENTS VIA E-IMPOTS</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q3.1. Indiquez les types de paiement que vous utilisez le plus souvent.</p>
									<Row>
										{['checkbox'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Télépaiement"
													name="q31"
													id={`checkbox-${type}-1`}
													type={type}
													checked={questionnaireInfo.q31value1 == "Télépaiement"}
													value="Télépaiement"
													onChange={handleQ31value1Change} />
												<Form.Check
													inline
													label="Virement bancaire"
													name="q31"
													id={`checkbox-${type}-2`}
													type={type}
													checked={questionnaireInfo.q31value2 == "Virement bancaire"}
													value="Virement bancaire"
													onChange={handleQ31value2Change} />
												<Form.Check
													inline
													label="Chèque"
													name="q31"
													id={`checkbox-${type}-3`}
													type={type}
													checked={questionnaireInfo.q31value3 == "Chèque"}
													value="Chèque"
													onChange={handleQ31value3Change} />
												<Form.Check
													inline
													label="Espèce"
													name="q31"
													id={`checkbox-${type}-4`}
													type={type}
													checked={questionnaireInfo.q31value4 == "Espèce"}
													value="Espèce"
													onChange={handleQ31value4Change} />
												<Form.Check
													inline
													label="DGI mobile"
													name="q31"
													id={`checkbox-${type}-5`}
													type={type}
													checked={questionnaireInfo.q31value5 == "DGI mobile"}
													value="DGI mobile"
													onChange={handleQ31value5Change} />
											</div>
										))}
									</Row>
									<p className="mb-0">Q3.2. Êtes-vous satisfait des procédures de paiement à travers e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q32"
													id={`radio-${type}-24`}
													type={type}
													checked={questionnaireInfo.q32value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q32value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q32"
													id={`radio-${type}-25`}
													type={type}
													checked={questionnaireInfo.q32value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q32value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q32"
													id={`radio-${type}-26`}
													type={type}
													checked={questionnaireInfo.q32value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q32value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q32"
													id={`radio-${type}-27`}
													type={type}
													checked={questionnaireInfo.q32value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q32value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q32"
													id={`radio-${type}-28`}
													type={type}
													checked={questionnaireInfo.q32value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q32value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q3.3. Relevez les difficultés recontrées en faisant vos paiements via e-impots.</Form.Label>
										<Form.Control value={questionnaireInfo.q33value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q33value: e.target.value})} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q3.4. Quelles propositions faites-vous pour améliorer les procédures de paiement ?</Form.Label>
										<Form.Control value={questionnaireInfo.q34value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q34value: e.target.value})} />
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionIi} className="questionnaire-btn-precedent" variant="light">Précédent</Button>&nbsp;
									<Button onClick={gotoQuestionIiiSuiv} className="questionnaire-btn-suivant" variant="light">Suivant</Button>&nbsp;&nbsp;<span className={(errorQ3 == "") ? 'hideitem' : 'bg-error px-3 pb-1'}>{errorQ3}</span>
								</div>
							</Col>
							{/* question IV */}
							<Col className={(currentQuestion == 3) ? '' : 'hideitem'}>
								<h2 className="text-question-title">IV. DEPOT DES ETATS FINANCIERS VIA E-IMPOTS</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q4.1. Indiquez les années pour lesquelles vous avez effectué des dépôts en ligne.</p>
									<Row>
										{['checkbox'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="2018"
													name="q41"
													id={`checkbox-${type}-6`}
													type={type}
													checked={questionnaireInfo.q41value1 == "2018"}
													value="2018"
													onChange={handleQ41value1Change}
												/>
												<Form.Check
													inline
													label="2019"
													name="q41"
													id={`checkbox-${type}-7`}
													type={type}
													checked={questionnaireInfo.q41value2 == "2019"}
													value="2019"
													onChange={handleQ41value2Change}
												/>
												<Form.Check
													inline
													label="2020"
													name="q41"
													id={`checkbox-${type}-8`}
													type={type}
													checked={questionnaireInfo.q41value3 == "2020"}
													value="2020"
													onChange={handleQ41value3Change}
												/>
												<Form.Check
													inline
													label="2021"
													name="q41"
													id={`checkbox-${type}-9`}
													type={type}
													checked={questionnaireInfo.q41value4 == "2021"}
													value="2021"
													onChange={handleQ41value4Change}
												/>
												<Form.Check
													inline
													label="2022"
													name="q41"
													id={`checkbox-${type}-10`}
													type={type}
													checked={questionnaireInfo.q41value5 == "2022"}
													value="2022"
													onChange={handleQ41value5Change}
												/>
												<Form.Check
													inline
													label="2023"
													name="q41"
													id={`checkbox-${type}-11`}
													type={type}
													checked={questionnaireInfo.q41value6 == "2023"}
													value="2023"
													onChange={handleQ41value6Change}
												/>
											</div>
										))}
									</Row>
									<p className="mb-0">Q4.2. Êtes-vous satisfait des procédures de dépôt des états financiers à travers e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q42"
													id={`radio-${type}-35`}
													type={type}
													checked={questionnaireInfo.q42value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q42value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q42"
													id={`radio-${type}-36`}
													type={type}
													checked={questionnaireInfo.q42value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q42value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q42"
													id={`radio-${type}-37`}
													type={type}
													checked={questionnaireInfo.q42value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q42value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q42"
													id={`radio-${type}-38`}
													type={type}
													checked={questionnaireInfo.q42value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q42value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q42"
													id={`radio-${type}-39`}
													type={type}
													checked={questionnaireInfo.q42value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q42value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q4.3. Relevez les difficultés recontrées en faisant vos dépôt via e-impots.</Form.Label>
										<Form.Control value={questionnaireInfo.q43value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q43value: e.target.value})} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q4.4. Quelles propositions faites-vous pour améliorer les procédures de dépôt ?</Form.Label>
										<Form.Control value={questionnaireInfo.q44value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q44value: e.target.value})} />
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionIii} className="questionnaire-btn-precedent" variant="light">Précédent</Button>&nbsp;
									<Button onClick={gotoQuestionIvSuiv} className="questionnaire-btn-suivant" variant="light">Suivant</Button>&nbsp;&nbsp;
									<span className={(errorQ4 == "") ? 'hideitem' : 'bg-error px-3 pb-1'}>{errorQ4}</span>
								</div>
							</Col>
							{/* question V */}
							<Col className={(currentQuestion == 4) ? '' : 'hideitem'}>
								<h2 className="text-question-title">V. REMBOURSEMENT DE CREDITS TVA VIA E-IMPOTS (le cas échéant)</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q5.1. Avez-vous déjà effectué une demande de remboursement de crédits TVA via e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="OUI"
													name="q51"
													id={`radio-${type}-40`}
													type={type}
													checked={questionnaireInfo.q51value == "OUI"}
													value="OUI"
													onChange={handleQ51valueChange}
												/>
												<Form.Check
													inline
													label="NON"
													name="q51"
													id={`radio-${type}-41`}
													type={type}
													checked={questionnaireInfo.q51value == "NON"}
													value="NON"
													onChange={handleQ51valueChange}
												/>
											</div>
										))}
									</Row>
									<Form.Group className={(!q52Enable) ? 'hideitem' : 'mb-3 form-select-monthyear'}>
										<Form.Label className="mb-1">Q5.2. Si OUI, indiquez la période de la première demande (mois/année) :</Form.Label>&nbsp;
										<div className="form-select-date flex">
											<Form.Select value={selectedDateMonth} className="form-select-datemonth" aria-label="MM" onChange={handleDatemonthSelect}>
												<option value="">---</option>
												<option value="01">1</option>
												<option value="02">2</option>
												<option value="03">3</option>
												<option value="04">4</option>
												<option value="05">5</option>
												<option value="06">6</option>
												<option value="07">7</option>
												<option value="08">8</option>
												<option value="09">9</option>
												<option value="10">10</option>
												<option value="11">11</option>
												<option value="12">12</option>
											</Form.Select>
											<Form.Select value={selectedDateYear} className="form-select-dateyear" aria-label="YYYY" onChange={handleDateyearSelect}>
												<option value="">---</option>
												<option value="2020">2020</option>
												<option value="2021">2021</option>
												<option value="2022">2022</option>
												<option value="2023">2023</option>
												<option value="2024">2024</option>
												<option value="2025">2025</option>
											</Form.Select>
										</div>
									</Form.Group>
									<p className="mb-0">Q5.3. Êtes-vous satisfait des procédures de demande de remboursement à travers e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q53"
													id={`radio-${type}-42`}
													type={type}
													checked={questionnaireInfo.q53value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q53value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q53"
													id={`radio-${type}-43`}
													type={type}
													checked={questionnaireInfo.q53value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q53value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q53"
													id={`radio-${type}-44`}
													type={type}
													checked={questionnaireInfo.q53value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q53value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q53"
													id={`radio-${type}-45`}
													type={type}
													checked={questionnaireInfo.q53value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q53value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q53"
													id={`radio-${type}-46`}
													type={type}
													checked={questionnaireInfo.q53value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q53value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q5.4. Relevez les difficultés recontrées en faisant vos demandes via e-impots.</Form.Label>
										<Form.Control value={questionnaireInfo.q54value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q54value: e.target.value})} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q5.5. Quelles proposition faites-vous pour améliorer les procédures de remboursement ?</Form.Label>
										<Form.Control value={questionnaireInfo.q55value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q55value: e.target.value})}/>
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionIv} className="questionnaire-btn-precedent" variant="light">Précédent</Button>&nbsp;
									<Button onClick={gotoQuestionVSuiv} className="questionnaire-btn-suivant" variant="light">Suivant</Button>&nbsp;&nbsp;
									<span className={(errorQ5 == "") ? 'hideitem' : 'bg-error px-3 pb-1'}>{errorQ5}</span>
								</div>
							</Col>
							{/* question VI */}
							<Col className={(currentQuestion == 5) ? '' : 'hideitem'}>
								<h2 className="text-question-title">VI. ACTES ADMINISTRATIFS VIA <br/>E-IMPOTS</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q6.1. Avez-vous déjà effectué une demande d'actes administratifs via e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="OUI"
													name="q61"
													id={`radio-${type}-47`}
													type={type}
													checked={questionnaireInfo.q61value == "OUI"}
													value="OUI"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q61value: e.target.value})}
												/>
												<Form.Check
													inline
													label="NON"
													name="q61"
													id={`radio-${type}-48`}
													type={type}
													checked={questionnaireInfo.q61value == "NON"}
													value="NON"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q61value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<p className="mb-0">Q6.2. Êtes-vous satisfait des procédures de demande d'actes à travers e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q62"
													id={`radio-${type}-49`}
													type={type}
													checked={questionnaireInfo.q62value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q62value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q62"
													id={`radio-${type}-50`}
													type={type}
													checked={questionnaireInfo.q62value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q62value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q62"
													id={`radio-${type}-51`}
													type={type}
													checked={questionnaireInfo.q62value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q62value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q62"
													id={`radio-${type}-52`}
													type={type}
													checked={questionnaireInfo.q62value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q62value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q62"
													id={`radio-${type}-53`}
													type={type}
													checked={questionnaireInfo.q62value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q62value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<p className="mb-0">Q6.3. Les actes sont-ils délivrés dans les délais indiqués ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="OUI"
													name="q63"
													id={`radio-${type}-54`}
													type={type}
													checked={questionnaireInfo.q63value == "OUI"}
													value="OUI"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q63value: e.target.value})}
												/>
												<Form.Check
													inline
													label="NON"
													name="q63"
													id={`radio-${type}-55`}
													type={type}
													checked={questionnaireInfo.q63value == "NON"}
													value="NON"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q63value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q6.4. Relevez les difficultés recontrées en faisant vos demandes d'actes via e-impots.</Form.Label>
										<Form.Control value={questionnaireInfo.q64value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q64value: e.target.value})} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q6.5. Quelles proposition faites-vous pour améliorer ces procédures ?</Form.Label>
										<Form.Control value={questionnaireInfo.q65value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q65value: e.target.value})} />
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionV} className="questionnaire-btn-precedent" variant="light">Précédent</Button>&nbsp;
									<Button onClick={gotoQuestionViSuiv} className="questionnaire-btn-suivant" variant="light">Suivant</Button>&nbsp;&nbsp;<span className={(errorQ6 == "") ? 'hideitem' : 'bg-error px-3 pb-1'}>{errorQ6}</span>
								</div>
							</Col>
							{/* question VII */}
							<Col className={(currentQuestion == 6) ? '' : 'hideitem'}>
								<h2 className="text-question-title">VII. APPRECIATION GENERALE</h2>
								<div className="bg-question-content">
									<p className="mb-0">Q7.1. Quelle est votre niveau de confiance en la sécurisation de vos données sur e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très confiant"
													name="q71"
													id={`radio-${type}-56`}
													type={type}
													checked={questionnaireInfo.q71value == "Très confiant"}
													value="Très confiant"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q71value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Moyennement confiant"
													name="q71"
													id={`radio-${type}-57`}
													type={type}
													checked={questionnaireInfo.q71value == "Moyennement confiant"}
													value="Moyennement confiant"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q71value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu confiant"
													name="q71"
													id={`radio-${type}-58`}
													type={type}
													checked={questionnaireInfo.q71value == "Peu confiant"}
													value="Peu confiant"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q71value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout confiant"
													name="q71"
													id={`radio-${type}-59`}
													type={type}
													checked={questionnaireInfo.q71value == "Pas du tout confiant"}
													value="Pas du tout confiant"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q71value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<p className="mb-0">Q7.2. Avez-vous reçu une quelconque assistance relative aux difficultés rencontrées sur e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="OUI"
													name="q72"
													id={`radio-${type}-60`}
													type={type}
													checked={questionnaireInfo.q72value == "OUI"}
													value="OUI"
													onChange={handleQ72valueChange}
												/>
												<Form.Check
													inline
													label="NON"
													name="q72"
													id={`radio-${type}-61`}
													type={type}
													checked={questionnaireInfo.q72value == "NON"}
													value="NON"
													onChange={handleQ72valueChange}
												/>
											</div>
										))}
									</Row>
									<div className={(!q73Enable) ? 'hideitem' : 'mb-0'}>
										<p className="mb-0">Q7.3. Si OUI, quels types d'assistance avez-vous reçu ?</p>
										<Row className={(!q73Enable) ? 'hideitem' : ''}>
											{['checkbox'].map((type) => (
												<div key={`inline-${type}`} className="mb-1">
													<Form.Check
														inline
														label="Physique (formation)"
														name="q73"
														id={`checkbox-${type}-12`}
														type={type}
														checked={questionnaireInfo.q73value1 == "Physique (formation)"}
														value="Physique (formation)"
														onChange={handleQ73value1Change}
													/>
													<Form.Check
														inline
														label="Electronique"
														name="q73"
														id={`checkbox-${type}-14`}
														type={type}
														checked={questionnaireInfo.q73value2 == "Electronique"}
														value="Electronique"
														onChange={handleQ73value2Change}
													/>
													<Form.Check
														inline
														label="Téléphonique"
														name="q73"
														id={`checkbox-${type}-15`}
														type={type}
														checked={questionnaireInfo.q73value3 == "Téléphonique"}
														value="Téléphonique"
														onChange={handleQ73value3Change}
													/>
													<Form.Check
														inline
														label="Brochures explicatives"
														name="q73"
														id={`checkbox-${type}-16`}
														type={type}
														checked={questionnaireInfo.q73value4 == "Brochures explicatives"}
														value="Brochures explicatives"
														onChange={handleQ73value4Change}
													/>
												</div>
											))}
											<Form.Group className="mb-3">
												<Form.Label className="mb-1">Autres à préciser :</Form.Label>
												<Form.Control as="textarea" rows={1} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q73value5: e.target.value})} />
											</Form.Group>				
										</Row>
									</div>								
									<p className="mb-0">Q7.4. Êtes-vous satisfait du programme d'assistance à l'utilisation de e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q74"
													id={`radio-${type}-66`}
													type={type}
													checked={questionnaireInfo.q74value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q74value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q74"
													id={`radio-${type}-67`}
													type={type}
													checked={questionnaireInfo.q74value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q74value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q74"
													id={`radio-${type}-68`}
													type={type}
													checked={questionnaireInfo.q74value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q74value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q74"
													id={`radio-${type}-69`}
													type={type}
													checked={questionnaireInfo.q74value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q74value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q74"
													id={`radio-${type}-70`}
													type={type}
													checked={questionnaireInfo.q74value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q74value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q7.5. Quelles propositions faites-vous pour améliorer l'assistance à l'utilisation de e-impots ?</Form.Label>
										<Form.Control value={questionnaireInfo.q75value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q75value: e.target.value})} />
									</Form.Group>
									<p className="mb-0">Q7.6. Dans quelles mesures e-impots a-t-elle impacté le temps mis pour faire vos démarches ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Forte réduction"
													name="q76"
													id={`radio-${type}-71`}
													type={type}
													checked={questionnaireInfo.q76value == "Forte réduction"}
													value="Forte réduction"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q76value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Faible réduction"
													name="q76"
													id={`radio-${type}-72`}
													type={type}
													checked={questionnaireInfo.q76value == "Faible réduction"}
													value="Faible réduction"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q76value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Aucun Changement"
													name="q76"
													id={`radio-${type}-73`}
													type={type}
													checked={questionnaireInfo.q76value == "Aucun Changement"}
													value="Aucun Changement"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q76value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Augmentation"
													name="q76"
													id={`radio-${type}-74`}
													type={type}
													checked={questionnaireInfo.q76value == "Augmentation"}
													value="Augmentation"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q76value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<p className="mb-0">Q7.7. Quelles est votre appréciation générale de e-impots ?</p>
									<Row>
										{['radio'].map((type) => (
											<div key={`inline-${type}`} className="mb-3">
												<Form.Check
													inline
													label="Très satisfait"
													name="q77"
													id={`radio-${type}-75`}
													type={type}
													checked={questionnaireInfo.q77value == "Très satisfait"}
													value="Très satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q77value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Satisfait"
													name="q77"
													id={`radio-${type}-76`}
													type={type}
													checked={questionnaireInfo.q77value == "Satisfait"}
													value="Satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q77value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Peu satisfait"
													name="q77"
													id={`radio-${type}-706`}
													type={type}
													checked={questionnaireInfo.q77value == "Peu satisfait"}
													value="Peu satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q77value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Pas du tout satisfait"
													name="q77"
													id={`radio-${type}-77`}
													type={type}
													checked={questionnaireInfo.q77value == "Pas du tout satisfait"}
													value="Pas du tout satisfait"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q77value: e.target.value})}
												/>
												<Form.Check
													inline
													label="Neutre"
													name="q77"
													id={`radio-${type}-78`}
													type={type}
													checked={questionnaireInfo.q77value == "Neutre"}
													value="Neutre"
													onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q77value: e.target.value})}
												/>
											</div>
										))}
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1">Q7.8. Quelles propositions faites-vous pour améliorer la plateforme e-impots ?</Form.Label>
										<Form.Control value={questionnaireInfo.q78value} as="textarea" rows={3} onChange={(e) => updateQuestionnaireInfo({...questionnaireInfo, q78value: e.target.value})} />
									</Form.Group>
								</div>
								<div className="nav-question-btn">
									<Button onClick={gotoQuestionVi} className="questionnaire-btn-precedent" variant="light">Précédent</Button>&nbsp;
									<Button onClick={gotoTerminer} variant="light">Terminer</Button>&nbsp;&nbsp;<span className={(errorQ7 == "") ? 'hideitem' : 'bg-error px-3 pb-1'}>{errorQ7}</span>
								</div>
							</Col>
						</Row>
					</div>
					<div className={(finQuestionnaire == true) ? 'bg-responses rounded' : 'hideitem'}>
						<p className="questionnaire-reponses-title">Relisez les réponses avant de soumettre le formulaire</p>
						<div ref={questionnaireContentRef} className="questionnaire-content">
							<h3 ref={questionItitle}>I. IDENTIFICATION</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q11label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q11value}</p>
								<h5 className="mb-0">{questionnaireInfo.q12label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q12value}</p>
								<h5 className="mb-0">{questionnaireInfo.q13label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q13value}</p>
								<h5 className="mb-0">{questionnaireInfo.q14label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q14value}</p>
								<h5 className="mb-0">{questionnaireInfo.q15label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q15value}</p>
								<h5 className="mb-0">{questionnaireInfo.q16label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q16value}</p>
							</div>
							<h3 ref={questionIItitle}>II. DECLARATIONS VIA E-IMPOTS</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q21label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q21value}</p>
								<h5 className="mb-0">{questionnaireInfo.q22label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q22value) ? "- - - - - - - - - - -" : questionnaireInfo.q22value}</p>
								<h5 className="mb-0">{questionnaireInfo.q23label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q23value) ? "- - - - - - - - - - -" : questionnaireInfo.q23value}</p>
							</div>
							<h3 ref={questionIIItitle}>III. PAIEMENTS VIA E-IMPOTS</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q31label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q31value1+' '+questionnaireInfo.q31value2+' '+questionnaireInfo.q31value3+' '+questionnaireInfo.q31value4+' '+questionnaireInfo.q31value5}</p>
								<h5 className="mb-0">{questionnaireInfo.q32label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q32value}</p>
								<h5 className="mb-0">{questionnaireInfo.q33label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q33value) ? "- - - - - - - - - - -" : questionnaireInfo.q33value}</p>
								<h5 className="mb-0">{questionnaireInfo.q34label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q34value) ? "- - - - - - - - - - -" : questionnaireInfo.q34value}</p>										
							</div>
							<h3 ref={questionIVtitle}>IV. DEPOT DES ETATS FINANCIERS VIA E-IMPOTS</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q41label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q41value1+' '+questionnaireInfo.q41value2+' '+questionnaireInfo.q41value3+' '+questionnaireInfo.q41value4+' '+questionnaireInfo.q41value5+' '+questionnaireInfo.q41value6}</p>
								<h5 className="mb-0">{questionnaireInfo.q42label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q42value}</p>
								<h5 className="mb-0">{questionnaireInfo.q43label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q43value) ? "- - - - - - - - - - -" : questionnaireInfo.q43value}</p>
								<h5 className="mb-0">{questionnaireInfo.q44label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q44value) ? "- - - - - - - - - - -" : questionnaireInfo.q44value}</p>
							</div>
							<h3 ref={questionVtitle}>V. REMBOURSEMENT DE CREDITS TVA VIA E-IMPOTS</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q51label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q51value}</p>
								<h5 className="mb-0">{questionnaireInfo.q52label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q52value) ? "- - - - / - - - - - - -" : questionnaireInfo.q52value}</p>
								<h5 className="mb-0">{questionnaireInfo.q53label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q53value}</p>
								<h5 className="mb-0">{questionnaireInfo.q54label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q54value) ? "- - - - - - - - - - -" : questionnaireInfo.q54value}</p>
								<h5 className="mb-0">{questionnaireInfo.q55label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q55value) ? "- - - - - - - - - - -" : questionnaireInfo.q55value}</p>
							</div>
							<h3 ref={questionVItitle}>VI. ACTES ADMINISTRATIFS VIA E-IMPOTS</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q61label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q61value}</p>
								<h5 className="mb-0">{questionnaireInfo.q62label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q62value}</p>
								<h5 className="mb-0">{questionnaireInfo.q63label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q63value}</p>
								<h5 className="mb-0">{questionnaireInfo.q64label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q64value) ? "- - - - - - - - - - -" : questionnaireInfo.q64value}</p>
								<h5 className="mb-0">{questionnaireInfo.q65label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q65value) ? "- - - - - - - - - - -" : questionnaireInfo.q65value}</p>
							</div>
							<h3 ref={questionVIItitle}>VII. APPRECIATION GENERALE</h3>
							<div className="px-4 py-2 border mb-3">
								<h5 className="mb-0">{questionnaireInfo.q71label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q71value}</p>
								<h5 className="mb-0">{questionnaireInfo.q72label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q72value}</p>
								<h5 className="mb-0">{questionnaireInfo.q73label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q73value1+' '+questionnaireInfo.q73value2+' '+questionnaireInfo.q73value3+' '+questionnaireInfo.q73value4+' '+questionnaireInfo.q73value5}</p>					
								<h5 className="mb-0">{questionnaireInfo.q74label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q74value}</p>
								<h5 className="mb-0">{questionnaireInfo.q75label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q75value) ? "- - - - - - - - - - -" : questionnaireInfo.q75value}</p>
								<h5 className="mb-0">{questionnaireInfo.q76label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q76value}</p>
								<h5 className="mb-0">{questionnaireInfo.q77label}</h5>
								<p id="questionnaire-content-item">{questionnaireInfo.q77value}</p>
								<h5 className="mb-0">{questionnaireInfo.q78label}</h5>
								<p id="questionnaire-content-item">{(!questionnaireInfo.q78value) ? "- - - - - - - - - - -" : questionnaireInfo.q78value}</p>
							</div>
						</div>
						<div className="questionnaire-btn-submit">
							<Button onClick={recommencerQuestionnaire} variant="warning">
								EFFACER
							</Button>&nbsp;&nbsp;
							<Button className={(finQuestionnaire == true) ? '' : 'disabled'} onClick={validerQuestionnaire} variant="success">
								SOUMETTRE
							</Button>
						</div>
					</div>
				</Container>
				<br/><br/><br/><br/><br/>
				<PageFooter />
			</div>
			<div className={questionnaireOk ? 'display-success-message px-2 mb-14' : 'hideitem'}>
				<Alert variant="success" className="flex w-100 items-center">
					<p className="mb-0 mr-2 text-3xl text-success">La Direction Générale des Impôts vous rémercie de votre participation !</p>
					<Link to="/" className="text-success" title="Cliquez-ici pour revenir à l'accueil">
						<svg class="w-[40px] h-[40px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
							<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
						</svg>
					</Link>
				</Alert>
			</div>
		</div>				
	);
};

export default Esondageparticipation;

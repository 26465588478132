import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {Container, Stack, Navbar, Row, Col, Form, InputGroup, Button, Alert} from 'react-bootstrap';
import {ClipLoader, BarLoader} from 'react-spinners';
import {css} from '@emotion/react';
import {Link, useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Adminsondagechats from './Adminsondagechats';
import Adminsondageview from './Adminsondageview';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import OceanTheme from "fusioncharts/themes/fusioncharts.theme.ocean";
import CarbonTheme from "fusioncharts/themes/fusioncharts.theme.carbon";
import GammeTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import CandyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import ZuneTheme from "fusioncharts/themes/fusioncharts.theme.zune";
import UmberTheme from "fusioncharts/themes/fusioncharts.theme.umber";
import '../tailwind-file.css';

function Adminsondageindex() {
    const navigate = useNavigate(null);
    const [dataQuestionnaires, setDataquestionnaires] = useState([]);
    const [dataMoisdejanvier, setDatamoisdejanvier] = useState(0);
    const [dataMoisdefevrier, setDatamoisdefevrier] = useState(0);
    const [dataMoisdemars, setDatamoisdemars] = useState(0);
    const [dataMoisdavril, setDatamoisdavril] = useState(0);
    const [dataMoisdemai, setDatamoisdemai] = useState(0);
    const [dataMoisdejuin, setDatamoisdejuin] = useState(0);
    const [dataMoisdejuillet, setDatamoisdejuillet] = useState(0);
    const [dataMoisdaout, setDatamoisdaout] = useState(0);
    const [dataMoisdeseptembre, setDatamoisdeseptembre] = useState(0);
    const [dataMoisdoctobre, setDatamoisdoctobre] = useState(0);
    const [dataMoisdenovembre, setDatamoisdenovembre] = useState(0);
    const [dataMoisdedecembre, setDatamoisdedecembre] = useState(0);
    const [adminLog, setAdminlog] = useState(localStorage.getItem('adminId'));
    const [questionnaireView, setQuestionnaireview] = useState([]);
    const [viewQuestionn, setViewquestionn] = useState(false);
    const [viewModal, setViewmodal] = useState(false);
    const [compteurVisites, setCompteur] = useState(0);
    const [internetAvailable, setInternetavailable] = useState(false);
    const styleSpiner = css`
        display: block;
        margin: 0 auto;
    `;
    function getCurrentmonthyear(mth) {
        return mth+'025';
    };
    ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, OceanTheme, CarbonTheme, GammeTheme, CandyTheme, ZuneTheme, UmberTheme);  
    const listeDesMois = {
        chart: {
            caption: "Total des participants",
            theme: "gamme1",
            labelRotation: "90",
        },
        data: [
            {label: "JANVIER", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('1/2')).length},
            {label: "FEVRIER", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('2/2')).length},
            {label: "MARS", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('3/2')).length},
            {label: "AVRIL", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('4/2')).length},
            {label: "MAI", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('5/2')).length},
            {label: "JUIN", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('6/2')).length},
            {label: "JUILLET", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('7/2')).length},
            {label: "AOUT", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('8/2')).length},
            {label: "SEPTEMBRE", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('9/2')).length},
            {label: "OCTOBRE", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('10/2')).length},
            {label: "NOVEMBRE", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('11/2')).length},
            {label: "DECEMBRE", value: dataQuestionnaires.filter(item => item.moissondage === getCurrentmonthyear('12/2')).length},
        ]
    }; 
    const checkAdminlog = () => {
        if (!adminLog) {
            navigate('/connection');
        }
    };
    const getData = async() => {
        try {
            const response = await fetch('https://sondages.hermannlepatriote.com/participations');
            const result = await response.json();
            setInternetavailable(true);
            setDataquestionnaires(result.data);
            setDatamoisdejanvier(result.compteurjanvier);
            setDatamoisdefevrier(result.compteurfevrier);
            setDatamoisdemars(result.compteurmars);
            setDatamoisdavril(result.compteuravril);
            setDatamoisdemai(result.compteurmai);
            setDatamoisdejuin(result.compteurjuin);
            setDatamoisdejuillet(result.compteurjuillet);
            setDatamoisdaout(result.compteuraout);
            setDatamoisdeseptembre(result.compteurseptembre);
            setDatamoisdoctobre(result.compteuroctobre);
            setDatamoisdenovembre(result.compteurnovembre);
            setDatamoisdedecembre(result.compteurdecembre);
            const resp = await fetch('https://sondages.hermannlepatriote.com/compteursvisites');
            const compteur = await resp.json(); 
            setCompteur(compteur.compteurvisite);

        } catch(error) {
            setInternetavailable(false);
        }
    };
    function logoutFunction() {
        localStorage.removeItem('adminId');
        setAdminlog(false);
        navigate('/connection');
    };
    const handleDetails = (id) => {
        getQuestionnaire(id);
        setViewmodal(true);
        //setViewquestionn(true);
    };
    const colQuestionnaire = [
        {
            name: "Date",
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: "Domaine d'activité",
            selector: (row) => row.q11value,
            sortable: false,
        },
        {
            name: "Regime d'imposition",
            selector: (row) => row.q12value,
            sortable: false,
        },
        {
            name: "Options",
            cell: (row) => (
                <div>
                    <Button 
                        onClick={() => handleDetails(row.id)} 
                        variant="success">
                        Details
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const getQuestionnaire = async(sondageId) => {
        try {
            const response = await axios.get(`https://sondages.hermannlepatriote.com/sondage/${sondageId}`);
            setQuestionnaireview(response.data);
            setViewquestionn(true);
        } catch(error) {
            console.log(error);
        }
    };    
    function fermerViewquestionnaire() {
        setViewmodal(false);
        //setViewquestionn(false);
    };
    const handleStatistiques = () => {
        navigate('/admin-statistiques');
    };
    const handleSondages = () => {
        //navigate('/');
    };
    const handleQuestions = () => {
        navigate('/admin-questions');
    };
    const handleVisiteurs = () => {
        navigate('/admin-visiteurs');
    };    
    const daAgriculturedexportation = dataQuestionnaires.filter(row => row.q11value === 'agriculturedexportation').length;
    const daAgriculturevivriereelevage = dataQuestionnaires.filter(row => row.q11value === 'agriculturevivriereelevage').length;
    const daPechessylviculture = dataQuestionnaires.filter(row => row.q11value === 'pechesylviculture').length;
    const daAutresindustries = dataQuestionnaires.filter(row => row.q11value === 'autresindustries').length;
    const daBtp = dataQuestionnaires.filter(row => row.q11value === 'btp').length;
    const daEnergieeaugazelectricite = dataQuestionnaires.filter(row => row.q11value === 'energieeaugazelectricite').length;
    const daExtractionminiere = dataQuestionnaires.filter(row => row.q11value === 'extractionminiere').length;
    const daIndustriesagroalimentaires = dataQuestionnaires.filter(row => row.q11value === 'industriesagroalimentaires').length;
    const daBanquesetetsfinanciers = dataQuestionnaires.filter(row => row.q11value === 'banquesetetsfinanciers').length;
    const daCommerce = dataQuestionnaires.filter(row => row.q11value === 'commerce').length;
    const daServices = dataQuestionnaires.filter(row => row.q11value === 'services').length;
    const daAdministrationpublique = dataQuestionnaires.filter(row => row.q11value === 'administrationpublique').length;
    const daTelecommunications = dataQuestionnaires.filter(row => row.q11value === 'telecommunications').length;
    const daTransportscommunications = dataQuestionnaires.filter(row => row.q11value === 'transportscommunications').length;
    useEffect(() => {
        checkAdminlog();
        getData();
    }, [listeDesMois]);
    return (
        <div className="admin-view">
            <Adminsondageview show={viewModal} onClose={fermerViewquestionnaire} data={questionnaireView} />
            <div className="mb-0 admin-header" style={{ height: "3rem"}}>
				<div className="admin-header-title"> Espace Administration </div>
                <div>
                    <svg onClick={logoutFunction} id="pointer-mouse" className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                    </svg> 
                </div> 
			</div>
            <hr/>
            <div className={(internetAvailable) ? 'admin-sondage-blocs' : 'hideitem'}>
                <Row className="flex px-2">
                    <Col className="blocs-flex-col active-menu" id="pointer-mouse" onClick={handleSondages}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Sondages</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21a9 9 0 1 1 0-18c1.052 0 2.062.18 3 .512M7 9.577l3.923 3.923 8.5-8.5M17 14v6m-3-3h6"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg1">
                            {dataQuestionnaires.length}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleQuestions}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Questions</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-3 5h3m-6 0h.01M12 16h3m-6 0h.01M10 3v4h4V3h-4Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg2">
                            7
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleVisiteurs}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Visiteurs</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg3">
                            {compteurVisites}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleStatistiques}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Statistiques</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 6.025A7.5 7.5 0 1 0 17.975 14H10V6.025Z"/>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.5 3c-.169 0-.334.014-.5.025V11h7.975c.011-.166.025-.331.025-.5A7.5 7.5 0 0 0 13.5 3Z"/>
                                </svg>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <Link to="/">Accueil</Link>
            <hr/>
            <br/>
            <div className={(internetAvailable) ? 'bg-admin p-2 my-12 mx-8' : 'hideitem'}>
                <Row className="dashboard-blocs">
                    <ReactFC type="column2d" dataFormat="json" width="100%" dataSource={listeDesMois} />
                </Row>
            </div>
            <br/>
            <div className={(internetAvailable) ? 'bg-admin p-2 mt-10 mx-8' : 'hideitem'}>
                <Row className="dashboard-blocs">
                    <div className="m-1 dashboard-bloc-chart shadow-md">
                        <Adminsondagechats domaine1={daAgriculturedexportation} domaine2={daAgriculturevivriereelevage} domaine3={daPechessylviculture} domaine4={daAutresindustries} domaine5={daBtp} domaine6={daEnergieeaugazelectricite} domaine7={daExtractionminiere} domaine8={daIndustriesagroalimentaires} domaine9={daBanquesetetsfinanciers} domaine10={daCommerce} domaine11={daServices} domaine12={daAdministrationpublique} domaine13={daTelecommunications} domaine14={daTransportscommunications} />
                    </div>
                    <div className="m-1 dashboard-bloc-datatable p-0">
                        <p className="mb-1 text-xl">Liste des participants</p>
                        <DataTable 
                            className="shadow-md"
                            columns={colQuestionnaire}
                            data={dataQuestionnaires}
                            pagination
                            highlightOnHover />
                    </div>
                </Row>
            </div>
            <div className={(internetAvailable) ? 'hideitem' : 'page-erreur mt-4'}>
                <p className="flex flex-col align-items-center mb-0">
                    <BarLoader size={60} color={"green"} css={styleSpiner} />
                </p>
            </div>
        </div>
    );
}

export default Adminsondageindex;

import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import {ClipLoader, BarLoader} from 'react-spinners';
import {css} from '@emotion/react';
import Accordion from 'react-bootstrap/Accordion';
import {Link, useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Adminsondageview from './Adminsondageview';
import '../tailwind-file.css';
import Adminsondageviews from './Adminsondageviews';

function Adminsondagestatistiques() {
    const navigate = useNavigate(null);
    const [adminHorloge, setAdminhorloge] = useState('');
    const [dataQuestionnaires, setDataquestionnaires] = useState([]);
    const [compteurVisites, setCompteur] = useState(0);
    const [adminLog, setAdminlog] = useState(localStorage.getItem('adminId'));
    const [viewModal, setViewmodal] = useState(false);
    const [viewPrint, setViewprint] = useState(false);
    const [questionnaireView, setQuestionnaireview] = useState([]);
    const [viewQuestionn, setViewquestionn] = useState(false);
    const [internetAvailable, setInternetavailable] = useState(false);
    const [filterPeriodStart, setFilterperiodstart] = useState('');
    const [filterPeriodEnd, setFilterperiodend] = useState('');
    const [filterPeriodStarttmp, setFilterperiodstarttmp] = useState(null);
    const [filterPeriodEndtmp, setFilterperiodendtmp] = useState(null);
    const [filterPeriodString, setFilterperiodstring] = useState('Toutes les participations');
    const [filterPeriodStringstart, setFilterperiodstringstart] = useState('');
    const [filterPeriodStringend, setFilterperiodstringend] = useState('');
    const [compteursJanvier, setCompteursjanvier] = useState(0);
    const [compteursFevrier, setCompteursfevrier] = useState(0);
    const [compteursMars, setCompteursmars] = useState(0);
    const [compteursAvril, setCompteursavril] = useState(0);
    const [compteursMai, setCompteursmai] = useState(0);
    const [compteursJuin, setCompteursjuin] = useState(0);
    const [compteursJuillet, setCompteursjuillet] = useState(0);
    const [compteursAout, setCompteursaout] = useState(0);
    const [compteursSeptembre, setCompteursseptembre] = useState(0);
    const [compteursOctobre, setCompteursoctobre] = useState(0);
    const [compteursNovembre, setCompteursnovembre] = useState(0);
    const [compteursDecembre, setCompteursdecembre] = useState(0);
    const [compteurAgriculturedexportation, setCompteuragriculturedexportation] = useState(0);
    const [compteurAgriculturevivriereelevage, setCompteuragriculturevivriereelevage] = useState(0);
    const [compteurPechesylviculture, setCompteurpechesylviculture] = useState(0);
    const [compteurAutresindustries, setCompteurautresindustries] = useState(0);
    const [compteurBtp, setCompteurbtp] = useState(0);
    const [compteurEnergieeaugazelectricite, setCompteurenergieeaugazelectricite] = useState(0);
    const [compteurExtractionminiere, setCompteurextractionminiere] = useState(0);
    const [compteurIndustriesagroalimentaires, setCompteurindustriesagroalimentaires] = useState(0);
    const [compteurIndustriespetroliere, setCompteurindustriespetroliere] = useState(0);
    const [compteurBanquesetetsfinanciers, setCompteurbanquesetetsfinanciers] = useState(0);
    const [compteurCommerce, setCompteurcommerce] = useState(0);
    const [compteurServices, setCompteurservices] = useState(0);
    const [compteurAdministrationpublique, setCompteuradministrationpublique] = useState(0);
    const [compteurTelecommunications, setCompteurtelecommunications] = useState(0);
    const [compteurTransportscommunications, setCompteurtransportscommunications] = useState(0);
    const [compteurRegimedelentreprenant, setCompteurregimedelentreprenant] = useState(0);
    const [compteurRegimedesmicroentreprises, setCompteurregimedesmicroentreprises] = useState(0);
    const [compteurRegimedureelnormal, setCompteurregimedureelnormal] = useState(0);
    const [compteurRegimedureelsimplifie, setCompteurregimedureelsimplifie] = useState(0);
    const [compteurRegimespecifique, setCompteurregimespecifique] = useState(0);
    const styleSpiner = css`
        display: block;
        margin: 0 auto;
    `;
    const checkAdminlog = () => {
        if (!adminLog) {
            navigate('/connection');
        }
    };
    const getData = async() => {
        try {
            const response = await fetch('https://sondages.hermannlepatriote.com/participations');
            const result = await response.json();
            setDataquestionnaires(result.data);
            const resp = await fetch('https://sondages.hermannlepatriote.com/compteursvisites');
            const compteur = await resp.json(); 
            setInternetavailable(true);
            setCompteur(compteur.compteurvisite);
            //
            setCompteursjanvier(compteur.compteursjanvier);
            setCompteursfevrier(compteur.compteursfevrier);
            setCompteursmars(compteur.compteursmars);
            setCompteursavril(compteur.compteursavril);
            setCompteursmai(compteur.compteursmai);
            setCompteursjuin(compteur.compteursjuin);
            setCompteursjuillet(compteur.compteursjuillet);
            setCompteursaout(compteur.compteursaout);
            setCompteursseptembre(compteur.compteursseptembre);
            setCompteursoctobre(compteur.compteursoctobre);
            setCompteursnovembre(compteur.compteursnovembre);
            setCompteursdecembre(compteur.compteursdecembre);
            //
            setCompteuragriculturedexportation(compteur.compteuragriculturedexportation);
            setCompteuragriculturevivriereelevage(compteur.compteuragriculturevivriereelevage);
            setCompteurpechesylviculture(compteur.compteurpechesylviculture);
            setCompteurautresindustries(compteur.compteurautresindustries);
            setCompteurbtp(compteur.compteurbtp);
            setCompteurenergieeaugazelectricite(compteur.compteurenergieeaugazelectricite);
            setCompteurextractionminiere(compteur.compteurextractionminiere);
            setCompteurindustriesagroalimentaires(compteur.compteurindustriesagroalimentaires);
            setCompteurindustriespetroliere(compteur.compteurindustriespetroliere);
            setCompteurbanquesetetsfinanciers(compteur.compteurbanquesetetsfinanciers);
            setCompteurcommerce(compteur.compteurcommerce);
            setCompteurservices(compteur.compteurservices);
            setCompteuradministrationpublique(compteur.compteuradministrationpublique);
            setCompteurtelecommunications(compteur.compteurtelecommunications);
            setCompteurtransportscommunications(compteur.compteurtransportscommunications); 
            //
            setCompteurregimedelentreprenant(compteur.compteurregimedelentreprenant);
            setCompteurregimedesmicroentreprises(compteur.compteurregimedesmicroentreprises);
            setCompteurregimedureelnormal(compteur.compteurregimedureelnormal);
            setCompteurregimedureelsimplifie(compteur.compteurregimedureelsimplifie);
            setCompteurregimespecifique(compteur.compteurregimespecifique);
        } catch(error) {
            setInternetavailable(false);
        }
    };
    function logoutFunction() {
        localStorage.removeItem('adminId');
        setAdminlog(false);
        navigate('/connection');
    };
    const formatDeDate = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'long'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = new Intl.DateTimeFormat('fr-FR', {month:'short'}).format(date);
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${jr} ${jour} ${mois} ${annee} - ${heure}:${min}:${sec}`; 
	};
    const formatDeDateMoisAnnee = (date) => {
        const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		return `${mois}/${annee}`;
	};
    const handleDetails = (id) => {
        getQuestionnaire(id);
        setViewmodal(true);
    };
    const getQuestionnaire = async(sondageId) => {
        try {
            const response = await axios.get(`https://sondages.hermannlepatriote.com/sondage/${sondageId}`);
            setQuestionnaireview(response.data);
            setViewquestionn(true);
        } catch(error) {
            console.log(error);
        }
    };  
    function fermerViewquestionnaire() {
        setViewmodal(false);
        //setViewquestionn(false);
    }; 
    function printModalClose() {
        setViewprint(false);
    }
    const colQuestionnaire = [
        {
            name: "Id",
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: "Adresse Ip",
            selector: (row) => row.ipaddr,
            sortable: false,
        },
        {
            name: "Fonction",
            selector: (row) => row.q11value,
            sortable: false,
        },
        {
            name: "Date",
            selector: (row) => row.q12value,
            sortable: false,
        },
        {
            name: "Options",
            cell: (row) => (
                <div>
                    <Button 
                        onClick={() => handleDetails(row.id)} 
                        variant="success">
                        Details
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const [timstp, setTimstp] = useState(Math.floor(new Date().getTime()/1000));
    useEffect(() => {
        const interval = setInterval(() => {
			var datecourrante = new Date();
			setAdminhorloge(formatDeDate(datecourrante));				
		}, 1000);
        checkAdminlog();
        getData();
    }, []);
    const handleFilterdateStart = (e) => {
        const selDateStart = e.target.value;
        setFilterperiodstringstart(selDateStart);
        const tmpValue = new Date(selDateStart).getTime();
        setFilterperiodstart(selDateStart);
        setFilterperiodstarttmp(Math.floor(tmpValue/1000));
    };
    const handleFilterdateEnd = (e) => {
        const selDateEnd = e.target.value;
        setFilterperiodstringend(selDateEnd);
        const tmpValue = new Date(selDateEnd).getTime();
        setFilterperiodend(selDateEnd);
        setFilterperiodendtmp(Math.floor(tmpValue/1000));
    };
    const getFilterdata = async() => {
        const filterSondageData = {
            datedebut: filterPeriodStarttmp,
            datefin: filterPeriodEndtmp,
        };
        try {
			const response = await axios.post('https://sondages.hermannlepatriote.com/filtersondages', filterSondageData);
			if (response.data.erreur == "ok") {
				alert('Une erreur est survenue !');
			} else {
                setDataquestionnaires(response.data.data);			
			} 
		} catch(error) {
			console.log('erreurfiltre', error);
		}
    };
    const selectDataperiodic = async() => {
        /*const filterSondageData = {
            datedebut: filterPeriodStarttmp,
            datefin: filterPeriodEndtmp,
        };
        try {

        } catch(error) {
            console.log('erreurfiltre', error);
        }*/
    };
    const validerFiltreperiodic = () => {
        getFilterdata();
        setFilterperiodstring('Sondage du '+filterPeriodStringstart+' au '+filterPeriodStringend);
    };
    const displayFiltreperiodic = () => {
        selectDataperiodic();
        setViewprint(true);
    };
    const handleStatistiques = () => {
        navigate('/admin-statistiques');
    };
    const handleSondages = () => {
        navigate('/admin-sondage');
    };
    const handleQuestions = () => {
        navigate('/admin-questions');
    };
    const handleVisiteurs = () => {
        navigate('/admin-visiteurs');
    };
    const [currentMonth, setCurrentmonth] = useState(formatDeDateMoisAnnee(new Date()));

    return (
        <div className="statistiques-view">
            <Adminsondageview show={viewModal} onClose={fermerViewquestionnaire} data={questionnaireView} />
            <Adminsondageviews show={viewPrint} onClose={printModalClose} data={dataQuestionnaires} period={filterPeriodString} />
            <div className="mb-0 admin-header" style={{ height: "3rem"}}>
				<div className="admin-header-title flex items-center"> 
                    <Link to="/admin-sondage" title="Accueil admin-sondage">
                        <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
                        </svg>
                    </Link>
                    <span>Espace Administration</span>
                </div>
                <div>
                    <svg onClick={logoutFunction} id="pointer-mouse" className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                    </svg> 
                </div> 
			</div>
            <hr/>
            <div className={(internetAvailable) ? 'admin-sondage-blocs' : 'hideitem'}>
                <Row className="flex px-2">
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleSondages}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Sondages</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21a9 9 0 1 1 0-18c1.052 0 2.062.18 3 .512M7 9.577l3.923 3.923 8.5-8.5M17 14v6m-3-3h6"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg1">
                            {dataQuestionnaires.length}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleQuestions}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Questions</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-3 5h3m-6 0h.01M12 16h3m-6 0h.01M10 3v4h4V3h-4Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg2">
                            7
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleVisiteurs}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Visiteurs</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg3">
                            {compteurVisites}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col active-menu" id="pointer-mouse">
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Statistiques</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 6.025A7.5 7.5 0 1 0 17.975 14H10V6.025Z"/>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.5 3c-.169 0-.334.014-.5.025V11h7.975c.011-.166.025-.331.025-.5A7.5 7.5 0 0 0 13.5 3Z"/>
                                </svg>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='flex p-2 mb-4'>
                <span className="text-xs">Aujourd'hui le {adminHorloge}</span>
            </div>
            <hr/>
            <div className={(internetAvailable) ? 'p-2' : 'hideitem'}>
                <Row className="flex">
                    <Col className="m-2">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Statistiques par mois</Accordion.Header>
                                <Accordion.Body>
                                    <div className="stats-div-container">
                                        <p className={(currentMonth == '1/2025') ? 'bg6 text-white' : ''}>Janvier <span>{compteursJanvier}</span></p>
                                        <p className={(currentMonth == '2/2025') ? 'bg6 text-white' : ''}>Février <span>{compteursFevrier}</span></p>
                                        <p className={(currentMonth == '3/2025') ? 'bg6 text-white' : ''}>Mars <span>{compteursMars}</span></p>
                                        <p className={(currentMonth == '4/2025') ? 'bg6 text-white' : ''}>Avril <span>{compteursAvril}</span></p>
                                        <p className={(currentMonth == '5/2025') ? 'bg6 text-white' : ''}>Mai <span>{compteursMai}</span></p>
                                        <p className={(currentMonth == '6/2025') ? 'bg6 text-white' : ''}>Juin <span>{compteursJuin}</span></p>
                                        <p className={(currentMonth == '7/2025') ? 'bg6 text-white' : ''}>Juillet <span>{compteursJuillet}</span></p>
                                        <p className={(currentMonth == '8/2025') ? 'bg6 text-white' : ''}>Août <span>{compteursAout}</span></p>
                                        <p className={(currentMonth == '9/2025') ? 'bg6 text-white' : ''}>Septembre <span>{compteursSeptembre}</span></p>
                                        <p className={(currentMonth == '10/2025') ? 'bg6 text-white' : ''}>Octobre <span>{compteursOctobre}</span></p>
                                        <p className={(currentMonth == '11/2025') ? 'bg6 text-white' : ''}>Novembre <span>{compteursNovembre}</span></p>
                                        <p className={(currentMonth == '12/2025') ? 'bg6 text-white' : ''}>Décembre <span>{compteursDecembre}</span></p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>- par domaine d'activité</Accordion.Header>
                                <Accordion.Body>
                                    <div className="stats-div-container">
                                        <p>Agriculture d'exportation <span>{compteurAgriculturedexportation}</span></p>
                                        <p>Agriculture vivrière, élevage <span>{compteurAgriculturevivriereelevage}</span></p>
                                        <p>Pêche, sylviculture <span>{compteurPechesylviculture}</span></p>
                                        <p>Autres industries <span>{compteurAutresindustries}</span></p>
                                        <p>BTP <span>{compteurBtp}</span></p>
                                        <p>Energie (eau, gaz, électricité) <span>{compteurEnergieeaugazelectricite}</span></p>
                                        <p>Extraction minière <span>{compteurExtractionminiere}</span></p>
                                        <p>Industries agro-alimentaires <span>{compteurIndustriesagroalimentaires}</span></p>
                                        <p>Industries pétrolières <span>{compteurIndustriespetroliere}</span></p>
                                        <p>Banques et Ets financiers <span>{compteurBanquesetetsfinanciers}</span></p>
                                        <p>Commerce <span>{compteurCommerce}</span></p>
                                        <p>Services <span>{compteurServices}</span></p>
                                        <p>Administration publique <span>{compteurAdministrationpublique}</span></p>
                                        <p>Télécommunications <span>{compteurTelecommunications}</span></p>
                                        <p>Transports, communications <span>{compteurTransportscommunications}</span></p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>- par régime d'imposition</Accordion.Header>
                                <Accordion.Body>
                                    <div className="stats-div-container">
                                        <p>Régime de l'Entreprenant <span>{compteurRegimedelentreprenant}</span></p>
                                        <p>Régime des Microentreprises <span>{compteurRegimedesmicroentreprises}</span></p>
                                        <p>Régime du Réel Normal <span>{compteurRegimedureelnormal}</span></p>
                                        <p>Régime du Réel Simplifié <span>{compteurRegimedureelsimplifie}</span></p>
                                        <p>Régime spécifique <span>{compteurRegimespecifique}</span></p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>- par centre des impôts de gestion</Accordion.Header>
                                <Accordion.Body>
                                    <div className="stats-div-container"></div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col className="m-2">
                        <Row className="bg-gray1">
                            <Col>
                                <div className="stats-filter-datatable">
                                    <div>
                                        Période&nbsp;<input className="stats-filter-periodic" type="date" value={filterPeriodStart} onChange={handleFilterdateStart} />&nbsp;à&nbsp;<input className="stats-filter-periodic" type="date" value={filterPeriodEnd} onChange={handleFilterdateEnd} />
                                        <span className="stats-filter-btn" onClick={validerFiltreperiodic}>valider</span>
                                    </div>                                    
                                    <span className="stats-print-btn" onClick={displayFiltreperiodic}>
                                        <svg className="w-[20px] h-[20px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z" clip-rule="evenodd"/>
                                        </svg>
                                        imprimer
                                    </span>
                                </div>                                
                            </Col>
                        </Row>                        
                        <DataTable 
                            className="shadow-md"
                            columns={colQuestionnaire}
                            data={dataQuestionnaires}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            highlightOnHover />
                    </Col>
                </Row>
            </div>
            <div className={(internetAvailable) ? 'hideitem' : 'page-erreur mt-4'}>
                <p className="flex flex-col align-items-center mb-0">
                    <BarLoader size={60} color={"green"} css={styleSpiner} />
                </p>
            </div>
        </div>
    );
}

export default Adminsondagestatistiques;

import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {Container, Stack, Navbar, Row, Col, Form, Alert} from 'react-bootstrap';
import {ClipLoader, BarLoader} from 'react-spinners';
import {css} from '@emotion/react';
import {Link, useNavigate} from 'react-router-dom';
import Adminsondageview from './Adminsondageview';
import '../tailwind-file.css';

function Adminsondagequestions() {
    const navigate = useNavigate(null);
    const [adminHorloge, setAdminhorloge] = useState('');
    const [dataQuestionnaires, setDataquestionnaires] = useState([]);
    const [compteurVisites, setCompteur] = useState(0);
    const [adminLog, setAdminlog] = useState(localStorage.getItem('adminId'));
    const [internetAvailable, setInternetavailable] = useState(false);
    const [viewModal, setViewmodal] = useState(false);
    const [questionnaireView, setQuestionnaireview] = useState([]);
    const [viewQuestionn, setViewquestionn] = useState(false);
    const styleSpiner = css`
        display: block;
        margin: 0 auto;
    `;
    const checkAdminlog = () => {
        if (!adminLog) {
            navigate('/connection');
        }
    };
    const getData = async() => {
        try {
            const response = await fetch('https://sondages.hermannlepatriote.com/participations');
            const result = await response.json();
            setInternetavailable(true);
            setDataquestionnaires(result.data);
            const resp = await fetch('https://sondages.hermannlepatriote.com/compteursvisites');
            const compteur = await resp.json(); 
            setCompteur(compteur.compteurvisite);
        } catch(error) {
            setInternetavailable(false);
        }
        
    };
    function logoutFunction() {
        localStorage.removeItem('adminId');
        setAdminlog(false);
        navigate('/connection');
    };
    const formatDeDate = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'long'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = new Intl.DateTimeFormat('fr-FR', {month:'short'}).format(date);
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${jr} ${jour} ${mois} ${annee} - ${heure}:${min}:${sec}`; 
	};
    useEffect(() => {
        const interval = setInterval(() => {
			var datecourrante = new Date();
			setAdminhorloge(formatDeDate(datecourrante));				
		}, 1000);
        checkAdminlog();
        getData();
    }, []);
    const handleStatistiques = () => {
        navigate('/admin-statistiques');
    };
    const handleSondages = () => {
        navigate('/admin-sondage');
    };
    const handleVisiteurs = () => {
        navigate('/admin-visiteurs');
    };
    return (
        <div className="statistiques-view">
            <div className="mb-0 admin-header" style={{ height: "3rem"}}>
				<div className="admin-header-title flex items-center"> 
                    <Link to="/admin-sondage" title="Accueil admin-sondage">
                        <svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
                        </svg>
                    </Link>
                    <span>Espace Administration</span>
                </div>
                <div>
                    <svg onClick={logoutFunction} id="pointer-mouse" className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                    </svg> 
                </div> 
			</div>
            <hr/>
            <div className={(internetAvailable) ? 'admin-sondage-blocs' : 'hideitem'}>
                <Row className="flex px-2">
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleSondages}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Sondages</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21a9 9 0 1 1 0-18c1.052 0 2.062.18 3 .512M7 9.577l3.923 3.923 8.5-8.5M17 14v6m-3-3h6"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg1">
                            {dataQuestionnaires.length}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col active-menu" id="pointer-mouse">
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Questions</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-3 5h3m-6 0h.01M12 16h3m-6 0h.01M10 3v4h4V3h-4Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg2">
                            7
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleVisiteurs}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Visiteurs</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"/>
                                </svg>
                            </p>
                        </div>
                        <span className="bloc-count bg3">
                            {compteurVisites}
                        </span>
                    </Col>
                    <Col className="blocs-flex-col" id="pointer-mouse" onClick={handleStatistiques}>
                        <div className="blocs-flex-row">
                            <p className="mb-0 bloc-title">Statistiques</p>
                            <p>
                                <svg className="w-[34px] h-[34px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 6.025A7.5 7.5 0 1 0 17.975 14H10V6.025Z"/>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.5 3c-.169 0-.334.014-.5.025V11h7.975c.011-.166.025-.331.025-.5A7.5 7.5 0 0 0 13.5 3Z"/>
                                </svg>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="flex p-2 mb-4">
                <span className="text-xs">Aujourd'hui le {adminHorloge}</span>
            </div>
            <hr/>
            <div className={(internetAvailable) ? 'p-2' : 'hideitem'}>
                <h1 className="text-9xl text-center">
                    gestion des questions 
                </h1>
            </div>
            <div className={(internetAvailable) ? 'hideitem' : 'page-erreur mt-4'}> 
                <p className="flex flex-col align-items-center mb-0">
                    <BarLoader size={60} color={"green"} css={styleSpiner} />
                </p>
            </div>
        </div>
    );
}

export default Adminsondagequestions;

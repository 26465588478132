import React, {useRef} from 'react';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import './modal.css';

const Adminsondageview = ({show, onClose, data}) => {
    const printRef = useRef();
    const formatDeDate = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'long'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = new Intl.DateTimeFormat('fr-FR', {month:'short'}).format(date);
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${jr} ${jour} ${mois} ${annee} - ${heure}:${min}:${sec}`; 
	};
    const handlePrint = () => {
        const printDoc = printRef.current;
        const win = window.open('', '', 'width=1368, height=720');
        if (win) {
            win.document.write(`
                <html>
                    <head>
                        <title>Impression de questionnaire</title>
                        <style>
                            body {
                                margin: 2em;
                                display: flex;
                                flex-direction: column;
                                height: 100vh;
                                justify-content: space-between;
                            }
                            header {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin: 0 1em;
                            }
                            .head-top {
                                justify-content: space-between;
                                display: flex;
                                flex-direction: row;
                            }
                            .head-title, .head-title2 {
                                text-transform: uppercase;
                                font-size: 12px;
                                letter-spacing: 1px;
                            }
                            .head-title > p {
                                margin: 0;
                            }
                            .head-img, .head-title2, .head-title {
                                width: 30%;
                                text-align: center;
                            }
                            .head-title2 > p {
                                margin: 0;
                            }
                            .title2-min {
                                font-size: 11px;
                                text-transform: capitalize !important;
                            }
                            .page-title {
                                background: #797979;
                                font-size: 2em;
                                text-transform: uppercase;
                                font-family: serif;
                                font-weight: 400;
                                text-align: center;
                                padding: 10px 20px;
                                margin: 2em 0 1em 0;
                            }
                            .page-title > p {
                                margin: 0;
                            }
                            footer {
                                border-top: 1px solid #797979;
                                margin: 2em 4em;
                                color: #797979;
                                font-size: 11px;
                                text-align: center;
                                font-style: italic;
                                letter-spacing: 0.4px;
                                padding-bottom: 2em;
                            }
                            footer > p {
                                margin: 0;
                            }
                        </style>
                    </head>
                    <body>
                        <header>
                            <div class="head-top">
                                <div class="head-title">
                                    <p>ministere des finances et du budget</p>
                                    <p>--------</p>
                                    <p>direction generale des impots</p>
                                    <p>--------</p>
                                    <p>direction de la strategie des etudes et des statistiques fiscales</p>
                                </div>
                                <div class="head-img">
                                    <img src="https://sondages.hermannlepatriote.com/public/logo.png" height="100" width="150" alt="logoDGI" />
                                </div>
                                <div class="head-title2">
                                    <p>republique de cote d'ivoire</p>
                                    <p class="title2-min">union - discipline - travail</p>
                                    <p>--------</p>
                                </div>
                            </div>
                            <div class="page-title">
                                <p>questionnaire<br/>impact de la digitalisation sur la satisfaction des usagers-clients de la dgi</p>		
                            </div>
                        </header>		
                        <div>
                            ${printDoc.innerHTML}
                        </div>
                        <footer>
                            <p>DSESF - Abidjan - Plateau - Cité Administrative - Tour E - 12<sup>ème</sup> étage - BP V 103 Abidjan - Tél. 27 20 22 73 31 - Fax 27 20 22 73 31</p>
                            <p>Site web : <u>www.dgi.gouv.ci</u> - Email : <u>infodgi@dgi.gouv.ci</u> - Ligne verte : 800 88 888</p>
                        </footer>
                    </body>
                </html>      
            `);
            win.document.close();
            win.focus();
            setTimeout(() => {
                win.print();
                win.close();
            }, 500);
        }
    }; 
    if (!show) return null;
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <p className="mb-0">
                        Sondage du {data.date} | réalisé par {data.q16value}<br/><code className="text-xs">Mois/Jour/Année</code>
                    </p>
                    <div className="flex items-center">
                        <Button variant="dark" className="stats-print-btn mr-2" onClick={handlePrint} title="Impression des données">                            
                            <svg  className="w-[24px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z" clip-rule="evenodd"/>
                            </svg>                       
                        </Button>                        
                        <svg id="pointer-mouse" onClick={onClose} className="mr-4 w-[30px] h-[30px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.1" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                    </div>
                </div>
                <hr/>
                <div className="modal-body" ref={printRef}>
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between">
                            <p className="px-2 pt-3 mb-0">Participartion du {data.date}</p>
                            <p className="px-2 pt-3 mb-0 text-xs">{'Imprimé le '+formatDeDate(new Date())}</p>
                        </div>  
                        <hr/>
                        <br/>
                        <div className='rounded p-4 mb-5'>
                            <h3>I. IDENTIFICATION</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q11label}</h5>
                                <p>{data.q11value}</p>
                                <h5 className="mb-0">{data.q12label}</h5>
                                <p>{data.q12value}</p>
                                <h5 className="mb-0">{data.q13label}</h5>
                                <p>{data.q13value}</p>
                                <h5 className="mb-0">{data.q14label}</h5>
                                <p>{data.q14value}</p>
                                <h5 className="mb-0">{data.q15label}</h5>
                                <p>{data.q15value}</p>
                                <h5 className="mb-0">{data.q16label}</h5>
                                <p>{data.q16value}</p>
                            </div>
                            <br/><br/>
                            <h3>II. DECLARATIONS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q21label}</h5>
                                <p>{data.q21value}</p>
                                <h5 className="mb-0">{data.q22label}</h5>
                                <p>{(!data.q22value) ? "- - - - - - - - - - -" : data.q22value}</p>
                                <h5 className="mb-0">{data.q23label}</h5>
                                <p>{(!data.q23value) ? "- - - - - - - - - - -" : data.q23value}</p>
                            </div>
                            <br/><br/>
                            <h3>III. PAIEMENTS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q31label}</h5>
                                <p>{data.q31value1+' '+data.q31value2+' '+data.q31value3+' '+data.q31value4+' '+data.q31value5}</p>
                                <h5 className="mb-0">{data.q32label}</h5>
                                <p>{data.q32value}</p>
                                <h5 className="mb-0">{data.q33label}</h5>
                                <p>{(!data.q33value) ? "- - - - - - - - - - -" : data.q33value}</p>
                                <h5 className="mb-0">{data.q34label}</h5>
                                <p>{(!data.q34value) ? "- - - - - - - - - - -" : data.q34value}</p>										
                            </div>
                            <br/><br/>
                            <h3>IV. DEPOT DES ETATS FINANCIERS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q41label}</h5>
                                <p>{data.q41value1+' '+data.q41value2+' '+data.q41value3+' '+data.q41value4+' '+data.q41value5+' '+data.q41value6}</p>
                                <h5 className="mb-0">{data.q42label}</h5>
                                <p>{data.q42value}</p>
                                <h5 className="mb-0">{data.q43label}</h5>
                                <p>{(!data.q43value) ? "- - - - - - - - - - -" : data.q43value}</p>
                                <h5 className="mb-0">{data.q44label}</h5>
                                <p>{(!data.q44value) ? "- - - - - - - - - - -" : data.q44value}</p>
                            </div>
                            <br/><br/>
                            <h3>V. REMBOURSEMENT DE CREDITS TVA VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q51label}</h5>
                                <p>{data.q51value}</p>
                                <h5 className="mb-0">{data.q52label}</h5>
                                <p>{(!data.q52value) ? "- - - - / - - - - - - -" : data.q52value}</p>
                                <h5 className="mb-0">{data.q53label}</h5>
                                <p>{data.q53value}</p>
                                <h5 className="mb-0">{data.q54label}</h5>
                                <p>{(!data.q54value) ? "- - - - - - - - - - -" : data.q54value}</p>
                                <h5 className="mb-0">{data.q55label}</h5>
                                <p>{(!data.q55value) ? "- - - - - - - - - - -" : data.q55value}</p>
                            </div>
                            <br/><br/>
                            <h3>VI. ACTES ADMINISTRATIFS VIA E-IMPOTS</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q61label}</h5>
                                <p>{data.q61value}</p>
                                <h5 className="mb-0">{data.q62label}</h5>
                                <p>{data.q62value}</p>
                                <h5 className="mb-0">{data.q63label}</h5>
                                <p>{data.q63value}</p>
                                <h5 className="mb-0">{data.q64label}</h5>
                                <p>{(!data.q64value) ? "- - - - - - - - - - -" : data.q64value}</p>
                                <h5 className="mb-0">{data.q65label}</h5>
                                <p>{(!data.q65value) ? "- - - - - - - - - - -" : data.q65value}</p>
                            </div>
                            <br/><br/>
                            <h3>VII. APPRECIATION GENERALE</h3>
                            <div className="px-4 py-2 border mb-3">
                                <h5 className="mb-0">{data.q71label}</h5>
                                <p>{data.q71value}</p>
                                <h5 className="mb-0">{data.q72label}</h5>
                                <p>{data.q72value}</p>
                                <h5 className="mb-0">{data.q73label}</h5>
                                <p>{data.q73value1+' '+data.q73value2+' '+data.q73value3+' '+data.q73value4+' '+data.q73value5}</p>					
                                <h5 className="mb-0">{data.q74label}</h5>
                                <p>{data.q74value}</p>
                                <h5 className="mb-0">{data.q75label}</h5>
                                <p>{(!data.q75value) ? "- - - - - - - - - - -" : data.q75value}</p>
                                <h5 className="mb-0">{data.q76label}</h5>
                                <p>{data.q76value}</p>
                                <h5 className="mb-0">{data.q77label}</h5>
                                <p>{data.q77value}</p>
                                <h5 className="mb-0">{data.q78label}</h5>
                                <p>{(!data.q78value) ? "- - - - - - - - - - -" : data.q78value}</p>
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Adminsondageview;
